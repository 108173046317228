.membership-card {
  padding: 0 !important;
  margin: 0;
  position: relative; }
  @media all and (max-width: 1170px) {
    .membership-card {
      margin: 21px; } }
  .membership-card .membership-card-header,
  .membership-card .membership-card-content {
    padding: 42px 28px 21px; }
  .membership-card .membership-card-header .name-and-price {
    display: flex;
    flex-direction: column; }
    .membership-card .membership-card-header .name-and-price h4 {
      text-align: left;
      font-weight: 700;
      margin: 0 !important;
      font-size: 35.15625px !important;
      line-height: 35px; }
    .membership-card .membership-card-header .name-and-price > div {
      margin-top: 21px; }
      .membership-card .membership-card-header .name-and-price > div span {
        display: inline-block;
        color: #6757ec;
        line-height: 35px;
        display: inline; }
        .membership-card .membership-card-header .name-and-price > div span:nth-of-type(1) {
          font-size: 42px;
          font-family: pi-display, sans-serif;
          font-weight: 700; }
        .membership-card .membership-card-header .name-and-price > div span:nth-of-type(2) {
          font-size: 22.5px;
          font-weight: 400;
          margin-left: 7px; }
  .membership-card .membership-card-header .cta-button {
    margin-top: 21px;
    box-shadow: none !important; }
  .membership-card .membership-card-header .best-value {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    font-family: pi-text, sans-serif;
    padding: 7px 14px;
    border-radius: 3px 0px 0px 3px;
    position: absolute;
    top: 25px;
    right: 0;
    background-color: #6757ec;
    color: #fff; }
  .membership-card .membership-card-content {
    padding-bottom: 35px;
    padding-left: 14px;
    padding-top: 12px; }
    .membership-card .membership-card-content .membership-credits .permission {
      line-height: 35px;
      padding: 4.5px 0; }
    .membership-card .membership-card-content .membership-credits > div:nth-child(1) {
      padding-top: 0; }
    .membership-card .membership-card-content .membership-credits > div:nth-child(3) {
      padding-bottom: 0; }
    .membership-card .membership-card-content .divider {
      border-bottom: 1px solid #ebebf6;
      margin: 17px; }
    .membership-card .membership-card-content .membership-permissions .permission {
      line-height: 24.5px;
      padding: 6px 0; }
    .membership-card .membership-card-content .membership-permissions > div:nth-child(1) {
      padding-top: 0; }
    .membership-card .membership-card-content .permission {
      display: flex;
      flex-direction: row;
      font-size: 16px;
      color: #757378; }
      .membership-card .membership-card-content .permission .content {
        display: flex;
        align-items: center; }
        .membership-card .membership-card-content .permission .content.unavailable {
          color: #c8c8d3; }
      .membership-card .membership-card-content .permission .permission-icon {
        position: relative;
        top: 3px; }
      .membership-card .membership-card-content .permission .help-icon {
        align-items: center;
        display: flex;
        line-height: unset;
        margin-left: 12px; }
      .membership-card .membership-card-content .permission .icon {
        color: #01ba92;
        line-height: unset; }
      .membership-card .membership-card-content .permission.unavailable {
        color: #757378; }
        .membership-card .membership-card-content .permission.unavailable .icon {
          color: #757378; }
      .membership-card .membership-card-content .permission .right-icon {
        position: relative;
        top: 3px; }
    .membership-card .membership-card-content > div:nth-child(1) > div,
    .membership-card .membership-card-content > div:nth-child(2) > div,
    .membership-card .membership-card-content > div:nth-child(3) > div {
      left: 0; }
  @media (min-width: 992px) {
    .membership-card .membership-card-content .permission .right-icon {
      display: block; } }
