.searchbar-container {
  display: flex;
  align-items: center;
  width: 100%; }

.input-container {
  width: 100%; }
  .input-container .sui-search-box {
    display: flex;
    justify-content: flex-start; }
    .input-container .sui-search-box .sui-search-box__wrapper {
      max-width: 350px;
      display: flex;
      flex-flow: nowrap;
      align-items: center; }
      @media all and (max-width: 480px) {
        .input-container .sui-search-box .sui-search-box__wrapper {
          min-width: unset; } }
      .input-container .sui-search-box .sui-search-box__wrapper .icon-wrapper {
        position: absolute;
        left: 3px;
        top: 5px;
        z-index: 99; }
        .input-container .sui-search-box .sui-search-box__wrapper .icon-wrapper .icon {
          color: #231f20;
          cursor: pointer; }
      .input-container .sui-search-box .sui-search-box__wrapper input {
        padding-left: 40px;
        border: 1px solid #c8c8d3;
        height: 51px;
        font-size: 14px; }
      .input-container .sui-search-box .sui-search-box__wrapper input:focus {
        border: 1px solid #6757ec;
        color: #231f20; }
    .input-container .sui-search-box .sui-search-box__wrapper:focus-within .icon {
      color: #6757ec; }
  .input-container .sui-search-box__submit {
    display: none; }
  .input-container .sui-search-box__autocomplete-container {
    width: max-content; }
    @media all and (max-width: 1170px) {
      .input-container .sui-search-box__autocomplete-container {
        border: none;
        box-shadow: none;
        height: calc(100% - 80px);
        overflow-y: scroll;
        padding: 7px;
        position: fixed;
        top: 80px;
        width: 100vw !important;
        z-index: 9999; }
        .input-container .sui-search-box__autocomplete-container.isIOS {
          height: 45% !important; }
        .input-container .sui-search-box__autocomplete-container .sui-search-box__results-list {
          height: 100%; }
        .input-container .sui-search-box__autocomplete-container .sui-search-box__autocomplete-result {
          margin: 12px 0 16px 0;
          display: flex;
          align-items: flex-start; }
          .input-container .sui-search-box__autocomplete-container .sui-search-box__autocomplete-result .result-img {
            padding: 3px 12px 0 16px; }
          .input-container .sui-search-box__autocomplete-container .sui-search-box__autocomplete-result .sui-search-box__autocomplete-result-item {
            color: #231f20;
            font-family: Open Sans, sans-serif;
            font-size: 14px;
            line-height: 19px;
            text-decoration: none; } }
  .input-container .sui-search-box__autocomplete-container::-webkit-scrollbar {
    width: 10px; }
  .input-container .sui-search-box__autocomplete-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 7px #ebebf6;
    border-radius: 10px; }
  .input-container .sui-search-box__autocomplete-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 7px #c8c8d3; }
  .input-container ul li {
    max-width: 920px; }
    @media all and (max-width: 1170px) {
      .input-container ul li {
        font-family: Open Sans, sans-serif;
        font-size: 14px;
        line-height: 19px;
        margin: 12px 0 16px 0; } }
  .input-container ul:not(.no-bullet) li:before {
    position: unset; }
