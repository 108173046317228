.free-section-block-modal {
  text-align: center; }
  .free-section-block-modal .title {
    font-family: pi-display, sans-serif;
    font-size: 26px;
    line-height: 30.16px; }
  .free-section-block-modal .text {
    font-size: 16px;
    line-height: 21px;
    margin: 21px 0;
    text-align: left;
    width: 480px; }
  .free-section-block-modal .action-buttons {
    padding-top: 14px; }
    .free-section-block-modal .action-buttons .signin-free-section-modal {
      margin-left: 49px;
      text-decoration: none; }
