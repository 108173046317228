ul {
  list-style-type: none;
  margin: 28px 0 56px;
  padding-left: 49px; }
  @media only screen and (max-width: 480px) {
    ul {
      padding-left: 28px;
      margin: 21px 0 28px; } }
  ul.no-spacing {
    margin: 0px;
    padding: 0px; }
  ul li {
    margin: 14px 0;
    max-width: 630px;
    position: relative; }
    @media only screen and (max-width: 480px) {
      ul li {
        font-size: 14.4px; } }
    ul li:first-child {
      margin-top: 0px; }
  ul:not(.no-bullet) li:before {
    position: absolute;
    content: '';
    background-color: #231f20;
    width: 7px;
    height: 7px;
    left: -28px;
    top: 0.51852rem; }
  ul.divided {
    margin: 0px 0px 28px 0px;
    padding: 0px; }
    ul.divided li:not(:last-child) {
      border-bottom: 1px solid #ebebf6; }
    ul.divided li {
      margin: 0px; }
      ul.divided li .item-nav {
        text-align: left; }
  ul.b {
    font-size: 22.5px;
    line-height: 1.94444rem;
    margin: 14px 0 84px;
    padding-left: 56px; }
    @media only screen and (max-width: 820px) {
      ul.b {
        font-size: 21px;
        line-height: 31.5px; } }
    ul.b li {
      max-width: 784px;
      margin: 0.97222rem 0; }
      ul.b li:before {
        top: 0.77778rem;
        left: -35px; }
  ul.s {
    font-size: 14.4px;
    line-height: 1.16667rem;
    margin: 14px 0 42px;
    padding-left: 42px; }
    ul.s li {
      max-width: 784px;
      color: #757378;
      margin: 0.77778rem 0; }
      ul.s li:before {
        top: 8px;
        left: -21px;
        background-color: #757378;
        width: 5px;
        height: 5px; }
  ul.xs {
    font-size: 12px;
    line-height: 0.97222rem;
    margin: 14px 0 28px;
    padding-left: 28px; }
    ul.xs li {
      max-width: 784px;
      color: #757378;
      margin: 0.77778rem 0; }
      ul.xs li:before {
        top: 6px;
        left: -21px;
        background-color: #000;
        width: 5px;
        height: 5px; }

ol {
  list-style-type: none;
  margin: 28px 0 56px;
  padding-left: 49px;
  counter-reset: li; }
  ol li {
    counter-increment: li;
    position: relative;
    top: 0;
    left: 0;
    max-width: 630px;
    margin: 14px 0; }
    ol li:first-child {
      margin-top: 0px; }
    @media only screen and (max-width: 480px) {
      ol li {
        font-size: 14.4px; } }
  ol:not(.no-bullet) li:before {
    width: 35px;
    font-family: pi-text, sans-serif;
    left: -49px;
    top: 1px;
    text-align: right;
    position: absolute;
    content: counter(li) ".";
    display: inline-block;
    font-feature-settings: 'tnum', 'ss01'; }
  ol.divided {
    margin: 0px 0px 28px 0px;
    padding: 0px; }
    ol.divided li:not(:last-child) {
      border-bottom: 1px solid #ebebf6; }
    ol.divided li {
      margin: 0px; }
      ol.divided li .item-nav {
        text-align: left; }
  ol.b {
    font-size: 22.5px;
    line-height: 1.94444rem;
    margin: 14px 0 84px;
    padding-left: 56px; }
    ol.b li {
      max-width: 784px;
      margin: 0.97222rem 0; }
      ol.b li:before {
        width: 42px;
        left: -56px;
        top: 2px; }
  ol.s {
    font-size: 14.4px;
    line-height: 1.16667rem;
    margin: 14px 0 42px;
    padding-left: 42px; }
    ol.s li {
      max-width: 784px;
      color: #757378;
      margin: 0.77778rem 0; }
      ol.s li:before {
        width: 28px;
        left: -42px; }
  ol.xs {
    font-size: 12px;
    line-height: 0.97222rem;
    margin: 14px 0 28px;
    padding-left: 28px; }
    ol.xs li {
      max-width: 784px;
      color: #757378;
      margin: 0.77778rem 0; }
      ol.xs li:before {
        width: 21px;
        left: -28px; }

.from-top-enter {
  margin-top: -20px; }

.from-top-enter.from-top-enter-active {
  margin-top: 0px; }

.from-top-leave {
  margin-top: 0px; }

.from-top-leave.from-top-leave-active {
  margin-top: -20px; }

.from-top-appear {
  margin-top: -20px; }

.from-top-appear.from-top-appear-active {
  margin-top: 0px; }

p, .card .card-metadata .metadata {
  font-family: Open Sans, sans-serif;
  font-size: 1rem;
  line-height: 1.55556rem;
  margin: 0;
  padding: 0 0 1.55556rem 0;
  max-width: 686px;
  color: inherit;
  word-break: break-word; }
  p a.button.link, .card .card-metadata .metadata a.button.link, p a.link.input, .card .card-metadata .metadata a.link.input {
    vertical-align: bottom; }
  p.b, .card .card-metadata .b.metadata {
    font-size: 22.5px;
    line-height: 1.94444rem;
    max-width: 784px;
    padding-bottom: 1.94444rem; }
    @media only screen and (max-width: 820px) {
      p.b, .card .card-metadata .b.metadata {
        font-size: 21px;
        line-height: 31.5px; } }
  p.xb, .card .card-metadata .xb.metadata {
    font-family: pi-text, sans-serif;
    font-size: 28.125px;
    line-height: 1.94444rem;
    max-width: 854px;
    padding-bottom: 1.94444rem; }
  p.s, .card .card-info .description, .card .card-metadata .s.metadata {
    color: #757378;
    font-size: 14.4px;
    line-height: 1.16667rem;
    max-width: 504px;
    padding-bottom: 1.16667rem; }
  p.xs, .card .card-metadata .xs.metadata, .card.info .description {
    color: #757378;
    font-size: 12px;
    line-height: 0.97222rem;
    max-width: 420px;
    padding-bottom: 0.97222rem; }

blockquote {
  border-left: 7px solid #c8c8d3;
  color: #4d4a4c;
  margin: 35px 0;
  padding: 21px 35px; }

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-family: pi-display, sans-serif;
  font-weight: bold;
  margin: 0;
  max-width: 90%;
  padding: 0; }

h4, .card .card-info .title,
.h4,
h5,
.h5,
h6,
.card.size-small .card-info .title,
.h6 {
  font-family: pi-text, sans-serif;
  font-feature-settings: 'kern', 'liga', 'pnum', 'ss04';
  font-weight: normal;
  margin: 0;
  max-width: 90%;
  padding: 0; }

h1,
.h1 {
  font-size: 57px;
  letter-spacing: -0.01em;
  line-height: 3.5rem;
  margin-bottom: 1.75rem;
  margin-top: 3.5rem;
  max-width: 980px; }
  @media only screen and (max-width: 820px) {
    h1,
    .h1 {
      font-size: 35.15625px;
      line-height: 2.33333rem;
      max-width: 686px;
      margin-top: 2.33333rem;
      margin-bottom: 1.16667rem; } }

h2,
.h2 {
  font-size: 43.94531px;
  line-height: 2.72222rem;
  max-width: 854px;
  margin-top: 2.72222rem;
  margin-bottom: 1.36111rem; }
  @media only screen and (max-width: 820px) {
    h2,
    .h2 {
      font-size: 28.125px;
      line-height: 1.94444rem;
      max-width: 854px;
      margin-top: 1.94444rem;
      margin-bottom: 0.97222rem; } }

h3,
.h3 {
  font-size: 35.15625px;
  line-height: 2.33333rem;
  max-width: 686px;
  margin-top: 2.33333rem;
  margin-bottom: 1.16667rem; }
  @media only screen and (max-width: 820px) {
    h3,
    .h3 {
      font-size: 22.5px;
      line-height: 1.55556rem;
      max-width: 504px;
      margin-top: 1.55556rem;
      margin-bottom: 0.77778rem; } }

h4, .card .card-info .title,
.h4 {
  font-size: 28.125px;
  line-height: 1.94444rem;
  max-width: 854px;
  margin-top: 1.94444rem;
  margin-bottom: 0.97222rem; }
  @media only screen and (max-width: 820px) {
    h4, .card .card-info .title,
    .h4 {
      font-size: 22.5px;
      line-height: 1.55556rem;
      max-width: 504px;
      margin-top: 1.55556rem;
      margin-bottom: 0.77778rem; } }

h5,
.h5 {
  font-size: 22.5px;
  line-height: 1.55556rem;
  max-width: 504px;
  margin-top: 1.55556rem;
  margin-bottom: 0.77778rem; }
  @media only screen and (max-width: 820px) {
    h5,
    .h5 {
      font-size: 18px;
      line-height: 1.36111rem;
      max-width: 504px;
      margin-top: 1.36111rem;
      margin-bottom: 0.68056rem; } }


h6,
.card.size-small .card-info .title,
.h6 {
  font-size: 18px;
  line-height: 1.36111rem;
  max-width: 504px;
  margin-top: 1.36111rem;
  margin-bottom: 0.68056rem; }
  @media only screen and (max-width: 820px) {
    
    h6,
    .card.size-small .card-info .title,
    .h6 {
      color: #4d4a4c;
      font-size: 14.4px;
      line-height: 1.16667rem;
      max-width: 504px;
      font-weight: 700;
      font-family: Open Sans, sans-serif; } }
  h6.xs, .card.size-small .card-info .xs.title, .card.info .title,
  .h6.xs {
    font-size: 12px;
    line-height: 0.97222rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: #4d4a4c;
    margin-bottom: 7px; }
    @media only screen and (max-width: 820px) {
      h6.xs, .card.size-small .card-info .xs.title, .card.info .title,
      .h6.xs {
        font-family: pi-text, sans-serif;
        margin-bottom: 7px; } }

h1:first-child,
.h1:first-child,
h2:first-child,
.h2:first-child,
h3:first-child,
.h3:first-child,
h4:first-child,
.card .card-info .title:first-child,
.h4:first-child,
h5:first-child,
.h5:first-child,
h6:first-child,
.card.size-small .card-info .title:first-child,
.h6:first-child {
  margin-top: 0px; }

h5 + h1 {
  margin-top: 0px; }

.gradient {
  position: relative; }
  .gradient > * {
    position: relative; }
  .gradient.gradient-brand:before {
    background-size: cover;
    background-position: center;
    background: #00c9b3;
    background: linear-gradient(342deg, #00c9b3 0%, #00aeb6 41%, #00a7b7 50%, #00a0b7 59%, #0073bb 98%);
    mix-blend-mode: normal; }
  .gradient.gradient-black:before {
    opacity: 0.8; }
  .gradient:before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;
    mix-blend-mode: overlay;
    background: linear-gradient(125deg, rgba(255, 255, 255, 0) -40%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0) 140%); }

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

*:focus {
  outline: none; }

body,
html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 400;
  background-color: #f5f5fa;
  font-family: Open Sans, sans-serif;
  color: #231f20;
  padding: 0;
  margin: 0;
  font-size: 18px;
  line-height: 1.5em;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }
  body:after,
  html:after {
    opacity: 0.2; }

body.loader {
  overflow: hidden; }
  body.loader .layout-full-width_content_container {
    overflow: hidden; }

a {
  color: #6757ec; }
  a:hover {
    color: #453b85; }

.container-max-width {
  max-width: 1204px;
  width: 100%;
  margin: auto; }
  @media all and (max-width: 480px) {
    .container-max-width {
      padding: 0 21px !important; } }

.transition-fast, .from-top-enter.from-top-enter-active, .from-top-leave.from-top-leave-active, .from-top-appear.from-top-appear-active, .icon, .button, .input, .card.linked .arrow, .card.linked .title {
  transition: all 0.1s ease-in-out; }

.transition, .card, .card.linked .arrow:after {
  transition: all 0.3s ease-in-out; }

.transition-slow {
  transition: all 1s ease-in-out; }

.color-white {
  color: #f5f5fa !important; }
  .color-white.darken1 {
    color: #ebebf6 !important; }
  .color-white.darken2 {
    color: #c8c8d3 !important; }
  .color-white.lighten1 {
    color: #fff !important; }

.hover-color-white:hover {
  color: #f5f5fa !important; }
  .hover-color-white:hover.hover-darken1 {
    color: #ebebf6 !important; }
  .hover-color-white:hover.hover-darken2 {
    color: #c8c8d3 !important; }
  .hover-color-white:hover.hover-lighten1 {
    color: #fff !important; }

.bg-color-white {
  background-color: #f5f5fa !important; }
  .bg-color-white.bg-darken1 {
    background-color: #ebebf6 !important; }
  .bg-color-white.bg-darken2 {
    background-color: #c8c8d3 !important; }
  .bg-color-white.bg-lighten1 {
    background-color: #fff !important; }

.hover-bg-color-white:hover {
  background-color: #f5f5fa !important; }
  .hover-bg-color-white:hover.hover-bg-darken1 {
    background-color: #ebebf6 !important; }
  .hover-bg-color-white:hover.hover-bg-darken2 {
    background-color: #c8c8d3 !important; }
  .hover-bg-color-white:hover.hover-bg-lighten1 {
    background-color: #fff !important; }

.li-color-white:before {
  background-color: #f5f5fa !important; }
  .li-color-white:before.darken1 {
    background-color: #ebebf6 !important; }
  .li-color-white:before.darken2 {
    background-color: #c8c8d3 !important; }
  .li-color-white:before.lighten1 {
    background-color: #fff !important; }

.li-hover-color-white:before {
  background-color: #f5f5fa !important; }
  .li-hover-color-white:before.darken1 {
    background-color: #ebebf6 !important; }
  .li-hover-color-white:before.darken2 {
    background-color: #c8c8d3 !important; }
  .li-hover-color-white:before.lighten1 {
    background-color: #fff !important; }

.color-black {
  color: #231f20 !important; }
  .color-black.lighten1 {
    color: #4d4a4c !important; }
  .color-black.lighten2 {
    color: #757378 !important; }
  .color-black.darken1 {
    color: #000 !important; }

.hover-color-black:hover {
  color: #231f20 !important; }
  .hover-color-black:hover.hover-lighten1 {
    color: #4d4a4c !important; }
  .hover-color-black:hover.hover-lighten2 {
    color: #757378 !important; }
  .hover-color-black:hover.hover-darken1 {
    color: #000 !important; }

.bg-color-black {
  background-color: #231f20 !important; }
  .bg-color-black.bg-lighten1 {
    background-color: #4d4a4c !important; }
  .bg-color-black.bg-lighten2 {
    background-color: #757378 !important; }
  .bg-color-black.bg-darken1 {
    background-color: #000 !important; }

.hover-bg-color-black:hover {
  background-color: #231f20 !important; }
  .hover-bg-color-black:hover.hover-bg-lighten1 {
    background-color: #4d4a4c !important; }
  .hover-bg-color-black:hover.hover-bg-lighten2 {
    background-color: #757378 !important; }
  .hover-bg-color-black:hover.hover-bg-darken1 {
    background-color: #000 !important; }

.li-color-black:before {
  background-color: #231f20 !important; }
  .li-color-black:before.lighten1 {
    background-color: #4d4a4c !important; }
  .li-color-black:before.lighten2 {
    background-color: #757378 !important; }
  .li-color-black:before.darken1 {
    background-color: #000 !important; }

.li-hover-color-black:before {
  background-color: #231f20 !important; }
  .li-hover-color-black:before.lighten1 {
    background-color: #4d4a4c !important; }
  .li-hover-color-black:before.lighten2 {
    background-color: #757378 !important; }
  .li-hover-color-black:before.darken1 {
    background-color: #000 !important; }

.color-aqua {
  color: #01b8c8 !important; }
  .color-aqua.darken1 {
    color: #116b74 !important; }
  .color-aqua.lighten1 {
    color: #7fdbe3 !important; }

.hover-color-aqua:hover {
  color: #01b8c8 !important; }
  .hover-color-aqua:hover.hover-darken1 {
    color: #116b74 !important; }
  .hover-color-aqua:hover.hover-lighten1 {
    color: #7fdbe3 !important; }

.bg-color-aqua {
  background-color: #01b8c8 !important; }
  .bg-color-aqua.bg-darken1 {
    background-color: #116b74 !important; }
  .bg-color-aqua.bg-lighten1 {
    background-color: #7fdbe3 !important; }

.hover-bg-color-aqua:hover {
  background-color: #01b8c8 !important; }
  .hover-bg-color-aqua:hover.hover-bg-darken1 {
    background-color: #116b74 !important; }
  .hover-bg-color-aqua:hover.hover-bg-lighten1 {
    background-color: #7fdbe3 !important; }

.li-color-aqua:before {
  background-color: #01b8c8 !important; }
  .li-color-aqua:before.darken1 {
    background-color: #116b74 !important; }
  .li-color-aqua:before.lighten1 {
    background-color: #7fdbe3 !important; }

.li-hover-color-aqua:before {
  background-color: #01b8c8 !important; }
  .li-hover-color-aqua:before.darken1 {
    background-color: #116b74 !important; }
  .li-hover-color-aqua:before.lighten1 {
    background-color: #7fdbe3 !important; }

.color-blue {
  color: #017fbd !important; }
  .color-blue.darken1 {
    color: #114e6e !important; }
  .color-blue.lighten1 {
    color: #7fbedd !important; }

.hover-color-blue:hover {
  color: #017fbd !important; }
  .hover-color-blue:hover.hover-darken1 {
    color: #114e6e !important; }
  .hover-color-blue:hover.hover-lighten1 {
    color: #7fbedd !important; }

.bg-color-blue, .Toastify .Toastify__toast-container .Toastify__toast.Toastify__toast--info {
  background-color: #017fbd !important; }
  .bg-color-blue.bg-darken1, .Toastify .Toastify__toast-container .bg-darken1.Toastify__toast.Toastify__toast--info {
    background-color: #114e6e !important; }
  .bg-color-blue.bg-lighten1, .Toastify .Toastify__toast-container .bg-lighten1.Toastify__toast.Toastify__toast--info {
    background-color: #7fbedd !important; }

.hover-bg-color-blue:hover {
  background-color: #017fbd !important; }
  .hover-bg-color-blue:hover.hover-bg-darken1 {
    background-color: #114e6e !important; }
  .hover-bg-color-blue:hover.hover-bg-lighten1 {
    background-color: #7fbedd !important; }

.li-color-blue:before {
  background-color: #017fbd !important; }
  .li-color-blue:before.darken1 {
    background-color: #114e6e !important; }
  .li-color-blue:before.lighten1 {
    background-color: #7fbedd !important; }

.li-hover-color-blue:before {
  background-color: #017fbd !important; }
  .li-hover-color-blue:before.darken1 {
    background-color: #114e6e !important; }
  .li-hover-color-blue:before.lighten1 {
    background-color: #7fbedd !important; }

.color-violet {
  color: #6757ec !important; }
  .color-violet.darken1 {
    color: #453b85 !important; }
  .color-violet.lighten1 {
    color: #b3abf5 !important; }

.hover-color-violet:hover {
  color: #6757ec !important; }
  .hover-color-violet:hover.hover-darken1 {
    color: #453b85 !important; }
  .hover-color-violet:hover.hover-lighten1 {
    color: #b3abf5 !important; }

.bg-color-violet, .Toastify .Toastify__toast-container .Toastify__toast.Toastify__toast--warning {
  background-color: #6757ec !important; }
  .bg-color-violet.bg-darken1, .Toastify .Toastify__toast-container .bg-darken1.Toastify__toast.Toastify__toast--warning {
    background-color: #453b85 !important; }
  .bg-color-violet.bg-lighten1, .Toastify .Toastify__toast-container .bg-lighten1.Toastify__toast.Toastify__toast--warning {
    background-color: #b3abf5 !important; }

.hover-bg-color-violet:hover {
  background-color: #6757ec !important; }
  .hover-bg-color-violet:hover.hover-bg-darken1 {
    background-color: #453b85 !important; }
  .hover-bg-color-violet:hover.hover-bg-lighten1 {
    background-color: #b3abf5 !important; }

.li-color-violet:before {
  background-color: #6757ec !important; }
  .li-color-violet:before.darken1 {
    background-color: #453b85 !important; }
  .li-color-violet:before.lighten1 {
    background-color: #b3abf5 !important; }

.li-hover-color-violet:before {
  background-color: #6757ec !important; }
  .li-hover-color-violet:before.darken1 {
    background-color: #453b85 !important; }
  .li-hover-color-violet:before.lighten1 {
    background-color: #b3abf5 !important; }

.color-green {
  color: #01ba92 !important; }
  .color-green.darken1 {
    color: #126c58 !important; }
  .color-green.lighten1 {
    color: #80dcc8 !important; }

.hover-color-green:hover {
  color: #01ba92 !important; }
  .hover-color-green:hover.hover-darken1 {
    color: #126c58 !important; }
  .hover-color-green:hover.hover-lighten1 {
    color: #80dcc8 !important; }

.bg-color-green, .Toastify .Toastify__toast-container .Toastify__toast.Toastify__toast--success {
  background-color: #01ba92 !important; }
  .bg-color-green.bg-darken1, .Toastify .Toastify__toast-container .bg-darken1.Toastify__toast.Toastify__toast--success {
    background-color: #126c58 !important; }
  .bg-color-green.bg-lighten1, .Toastify .Toastify__toast-container .bg-lighten1.Toastify__toast.Toastify__toast--success {
    background-color: #80dcc8 !important; }

.hover-bg-color-green:hover {
  background-color: #01ba92 !important; }
  .hover-bg-color-green:hover.hover-bg-darken1 {
    background-color: #126c58 !important; }
  .hover-bg-color-green:hover.hover-bg-lighten1 {
    background-color: #80dcc8 !important; }

.li-color-green:before {
  background-color: #01ba92 !important; }
  .li-color-green:before.darken1 {
    background-color: #126c58 !important; }
  .li-color-green:before.lighten1 {
    background-color: #80dcc8 !important; }

.li-hover-color-green:before {
  background-color: #01ba92 !important; }
  .li-hover-color-green:before.darken1 {
    background-color: #126c58 !important; }
  .li-hover-color-green:before.lighten1 {
    background-color: #80dcc8 !important; }

.color-red {
  color: #ec576b !important; }
  .color-red.darken1 {
    color: #873a45 !important; }
  .color-red.lighten1 {
    color: #f5abb5 !important; }

.hover-color-red:hover {
  color: #ec576b !important; }
  .hover-color-red:hover.hover-darken1 {
    color: #873a45 !important; }
  .hover-color-red:hover.hover-lighten1 {
    color: #f5abb5 !important; }

.bg-color-red, .Toastify .Toastify__toast-container .Toastify__toast.Toastify__toast--error {
  background-color: #ec576b !important; }
  .bg-color-red.bg-darken1, .Toastify .Toastify__toast-container .bg-darken1.Toastify__toast.Toastify__toast--error {
    background-color: #873a45 !important; }
  .bg-color-red.bg-lighten1, .Toastify .Toastify__toast-container .bg-lighten1.Toastify__toast.Toastify__toast--error {
    background-color: #f5abb5 !important; }

.hover-bg-color-red:hover {
  background-color: #ec576b !important; }
  .hover-bg-color-red:hover.hover-bg-darken1 {
    background-color: #873a45 !important; }
  .hover-bg-color-red:hover.hover-bg-lighten1 {
    background-color: #f5abb5 !important; }

.li-color-red:before {
  background-color: #ec576b !important; }
  .li-color-red:before.darken1 {
    background-color: #873a45 !important; }
  .li-color-red:before.lighten1 {
    background-color: #f5abb5 !important; }

.li-hover-color-red:before {
  background-color: #ec576b !important; }
  .li-hover-color-red:before.darken1 {
    background-color: #873a45 !important; }
  .li-hover-color-red:before.lighten1 {
    background-color: #f5abb5 !important; }

.text-centered {
  text-align: center; }

.text-right {
  text-align: right; }

.text-left {
  text-align: left; }

.badge {
  background-color: #4d4a4c;
  border-radius: 0.2em;
  color: #f5f5fa;
  vertical-align: middle;
  padding: 0 7px;
  font-size: 12px; }
  .badge.blue {
    background-color: #017fbd; }
  .badge.red {
    background-color: #ec576b; }
  .badge.violet {
    background-color: #6757ec; }
  .badge.aqua {
    background-color: #01b8c8; }
  .badge.green {
    background-color: #01ba92; }
  .badge.white {
    background-color: #f5f5fa; }
  .badge.black {
    background-color: #231f20; }

table {
  border: 1px solid #757378;
  border-spacing: 0;
  display: inline-table;
  font-size: 14.4px;
  margin: 35px 0;
  width: 100%; }
  table td {
    border: 1px solid #757378;
    padding: 7px; }

.accordion .header {
  font-family: pi-text, sans-serif; }

.accordion .wrap {
  background-color: #fff;
  border: 2px solid #f5f5fa; }
  .accordion .wrap .panel {
    overflow: hidden; }
    .accordion .wrap .panel:not(:last-child) {
      border-bottom: 2px solid #f5f5fa; }
    .accordion .wrap .panel .item {
      align-items: center;
      box-shadow: 0 0 1px 1px rgba(200, 200, 211, 0.3), 14px 14px 14px 0 rgba(200, 200, 211, 0.2);
      color: #757378;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 14px;
      position: relative;
      z-index: 2; }
      .accordion .wrap .panel .item.active {
        color: #4d4a4c; }
      .accordion .wrap .panel .item .title {
        flex-grow: 1;
        padding-right: 56px;
        max-width: max-content;
        text-decoration: none; }
        .accordion .wrap .panel .item .title h2 {
          font-size: 18px;
          color: #757378;
          font-family: Open Sans, sans-serif;
          font-weight: 400;
          line-height: inherit; }
        .accordion .wrap .panel .item .title:hover {
          text-decoration: underline; }
      .accordion .wrap .panel .item .metadata {
        text-align: right;
        display: flex;
        align-items: center; }
        .accordion .wrap .panel .item .metadata .time {
          font-size: 1rem;
          font-family: pi-text, sans-serif; }
        .accordion .wrap .panel .item .metadata .arrow {
          margin-left: 14px; }

ul.pagination {
  margin: 0;
  padding: 0;
  text-align: center; }
  ul.pagination li {
    display: inline-block; }
    ul.pagination li.selected a {
      color: #000; }
    ul.pagination li::before {
      display: none; }
  ul.pagination a {
    color: #c8c8d3;
    cursor: pointer;
    font-family: pi-text, sans-serif;
    padding: 7px 14px; }
    ul.pagination a:hover {
      color: #000; }
  @media all and (max-width: 820px) {
    ul.pagination .previous a,
    ul.pagination .next a {
      padding: 0; } }

.icon {
  vertical-align: middle;
  font-size: 42px;
  display: inline-block; }
  .icon.xs {
    font-size: 12px; }
  .icon.s {
    font-size: 14.4px; }
  .icon.b {
    font-size: 22.5px; }
  .icon.xb {
    font-size: 28.125px; }
  .icon.xxb {
    font-size: 35.15625px; }
  .icon.rotate-0 {
    transform: rotate(0deg); }
  .icon.rotate-1 {
    transform: rotate(1deg); }
  .icon.rotate-2 {
    transform: rotate(2deg); }
  .icon.rotate-3 {
    transform: rotate(3deg); }
  .icon.rotate-4 {
    transform: rotate(4deg); }
  .icon.rotate-5 {
    transform: rotate(5deg); }
  .icon.rotate-6 {
    transform: rotate(6deg); }
  .icon.rotate-7 {
    transform: rotate(7deg); }
  .icon.rotate-8 {
    transform: rotate(8deg); }
  .icon.rotate-9 {
    transform: rotate(9deg); }
  .icon.rotate-10 {
    transform: rotate(10deg); }
  .icon.rotate-11 {
    transform: rotate(11deg); }
  .icon.rotate-12 {
    transform: rotate(12deg); }
  .icon.rotate-13 {
    transform: rotate(13deg); }
  .icon.rotate-14 {
    transform: rotate(14deg); }
  .icon.rotate-15 {
    transform: rotate(15deg); }
  .icon.rotate-16 {
    transform: rotate(16deg); }
  .icon.rotate-17 {
    transform: rotate(17deg); }
  .icon.rotate-18 {
    transform: rotate(18deg); }
  .icon.rotate-19 {
    transform: rotate(19deg); }
  .icon.rotate-20 {
    transform: rotate(20deg); }
  .icon.rotate-21 {
    transform: rotate(21deg); }
  .icon.rotate-22 {
    transform: rotate(22deg); }
  .icon.rotate-23 {
    transform: rotate(23deg); }
  .icon.rotate-24 {
    transform: rotate(24deg); }
  .icon.rotate-25 {
    transform: rotate(25deg); }
  .icon.rotate-26 {
    transform: rotate(26deg); }
  .icon.rotate-27 {
    transform: rotate(27deg); }
  .icon.rotate-28 {
    transform: rotate(28deg); }
  .icon.rotate-29 {
    transform: rotate(29deg); }
  .icon.rotate-30 {
    transform: rotate(30deg); }
  .icon.rotate-31 {
    transform: rotate(31deg); }
  .icon.rotate-32 {
    transform: rotate(32deg); }
  .icon.rotate-33 {
    transform: rotate(33deg); }
  .icon.rotate-34 {
    transform: rotate(34deg); }
  .icon.rotate-35 {
    transform: rotate(35deg); }
  .icon.rotate-36 {
    transform: rotate(36deg); }
  .icon.rotate-37 {
    transform: rotate(37deg); }
  .icon.rotate-38 {
    transform: rotate(38deg); }
  .icon.rotate-39 {
    transform: rotate(39deg); }
  .icon.rotate-40 {
    transform: rotate(40deg); }
  .icon.rotate-41 {
    transform: rotate(41deg); }
  .icon.rotate-42 {
    transform: rotate(42deg); }
  .icon.rotate-43 {
    transform: rotate(43deg); }
  .icon.rotate-44 {
    transform: rotate(44deg); }
  .icon.rotate-45 {
    transform: rotate(45deg); }
  .icon.rotate-46 {
    transform: rotate(46deg); }
  .icon.rotate-47 {
    transform: rotate(47deg); }
  .icon.rotate-48 {
    transform: rotate(48deg); }
  .icon.rotate-49 {
    transform: rotate(49deg); }
  .icon.rotate-50 {
    transform: rotate(50deg); }
  .icon.rotate-51 {
    transform: rotate(51deg); }
  .icon.rotate-52 {
    transform: rotate(52deg); }
  .icon.rotate-53 {
    transform: rotate(53deg); }
  .icon.rotate-54 {
    transform: rotate(54deg); }
  .icon.rotate-55 {
    transform: rotate(55deg); }
  .icon.rotate-56 {
    transform: rotate(56deg); }
  .icon.rotate-57 {
    transform: rotate(57deg); }
  .icon.rotate-58 {
    transform: rotate(58deg); }
  .icon.rotate-59 {
    transform: rotate(59deg); }
  .icon.rotate-60 {
    transform: rotate(60deg); }
  .icon.rotate-61 {
    transform: rotate(61deg); }
  .icon.rotate-62 {
    transform: rotate(62deg); }
  .icon.rotate-63 {
    transform: rotate(63deg); }
  .icon.rotate-64 {
    transform: rotate(64deg); }
  .icon.rotate-65 {
    transform: rotate(65deg); }
  .icon.rotate-66 {
    transform: rotate(66deg); }
  .icon.rotate-67 {
    transform: rotate(67deg); }
  .icon.rotate-68 {
    transform: rotate(68deg); }
  .icon.rotate-69 {
    transform: rotate(69deg); }
  .icon.rotate-70 {
    transform: rotate(70deg); }
  .icon.rotate-71 {
    transform: rotate(71deg); }
  .icon.rotate-72 {
    transform: rotate(72deg); }
  .icon.rotate-73 {
    transform: rotate(73deg); }
  .icon.rotate-74 {
    transform: rotate(74deg); }
  .icon.rotate-75 {
    transform: rotate(75deg); }
  .icon.rotate-76 {
    transform: rotate(76deg); }
  .icon.rotate-77 {
    transform: rotate(77deg); }
  .icon.rotate-78 {
    transform: rotate(78deg); }
  .icon.rotate-79 {
    transform: rotate(79deg); }
  .icon.rotate-80 {
    transform: rotate(80deg); }
  .icon.rotate-81 {
    transform: rotate(81deg); }
  .icon.rotate-82 {
    transform: rotate(82deg); }
  .icon.rotate-83 {
    transform: rotate(83deg); }
  .icon.rotate-84 {
    transform: rotate(84deg); }
  .icon.rotate-85 {
    transform: rotate(85deg); }
  .icon.rotate-86 {
    transform: rotate(86deg); }
  .icon.rotate-87 {
    transform: rotate(87deg); }
  .icon.rotate-88 {
    transform: rotate(88deg); }
  .icon.rotate-89 {
    transform: rotate(89deg); }
  .icon.rotate-90 {
    transform: rotate(90deg); }
  .icon.rotate-91 {
    transform: rotate(91deg); }
  .icon.rotate-92 {
    transform: rotate(92deg); }
  .icon.rotate-93 {
    transform: rotate(93deg); }
  .icon.rotate-94 {
    transform: rotate(94deg); }
  .icon.rotate-95 {
    transform: rotate(95deg); }
  .icon.rotate-96 {
    transform: rotate(96deg); }
  .icon.rotate-97 {
    transform: rotate(97deg); }
  .icon.rotate-98 {
    transform: rotate(98deg); }
  .icon.rotate-99 {
    transform: rotate(99deg); }
  .icon.rotate-100 {
    transform: rotate(100deg); }
  .icon.rotate-101 {
    transform: rotate(101deg); }
  .icon.rotate-102 {
    transform: rotate(102deg); }
  .icon.rotate-103 {
    transform: rotate(103deg); }
  .icon.rotate-104 {
    transform: rotate(104deg); }
  .icon.rotate-105 {
    transform: rotate(105deg); }
  .icon.rotate-106 {
    transform: rotate(106deg); }
  .icon.rotate-107 {
    transform: rotate(107deg); }
  .icon.rotate-108 {
    transform: rotate(108deg); }
  .icon.rotate-109 {
    transform: rotate(109deg); }
  .icon.rotate-110 {
    transform: rotate(110deg); }
  .icon.rotate-111 {
    transform: rotate(111deg); }
  .icon.rotate-112 {
    transform: rotate(112deg); }
  .icon.rotate-113 {
    transform: rotate(113deg); }
  .icon.rotate-114 {
    transform: rotate(114deg); }
  .icon.rotate-115 {
    transform: rotate(115deg); }
  .icon.rotate-116 {
    transform: rotate(116deg); }
  .icon.rotate-117 {
    transform: rotate(117deg); }
  .icon.rotate-118 {
    transform: rotate(118deg); }
  .icon.rotate-119 {
    transform: rotate(119deg); }
  .icon.rotate-120 {
    transform: rotate(120deg); }
  .icon.rotate-121 {
    transform: rotate(121deg); }
  .icon.rotate-122 {
    transform: rotate(122deg); }
  .icon.rotate-123 {
    transform: rotate(123deg); }
  .icon.rotate-124 {
    transform: rotate(124deg); }
  .icon.rotate-125 {
    transform: rotate(125deg); }
  .icon.rotate-126 {
    transform: rotate(126deg); }
  .icon.rotate-127 {
    transform: rotate(127deg); }
  .icon.rotate-128 {
    transform: rotate(128deg); }
  .icon.rotate-129 {
    transform: rotate(129deg); }
  .icon.rotate-130 {
    transform: rotate(130deg); }
  .icon.rotate-131 {
    transform: rotate(131deg); }
  .icon.rotate-132 {
    transform: rotate(132deg); }
  .icon.rotate-133 {
    transform: rotate(133deg); }
  .icon.rotate-134 {
    transform: rotate(134deg); }
  .icon.rotate-135 {
    transform: rotate(135deg); }
  .icon.rotate-136 {
    transform: rotate(136deg); }
  .icon.rotate-137 {
    transform: rotate(137deg); }
  .icon.rotate-138 {
    transform: rotate(138deg); }
  .icon.rotate-139 {
    transform: rotate(139deg); }
  .icon.rotate-140 {
    transform: rotate(140deg); }
  .icon.rotate-141 {
    transform: rotate(141deg); }
  .icon.rotate-142 {
    transform: rotate(142deg); }
  .icon.rotate-143 {
    transform: rotate(143deg); }
  .icon.rotate-144 {
    transform: rotate(144deg); }
  .icon.rotate-145 {
    transform: rotate(145deg); }
  .icon.rotate-146 {
    transform: rotate(146deg); }
  .icon.rotate-147 {
    transform: rotate(147deg); }
  .icon.rotate-148 {
    transform: rotate(148deg); }
  .icon.rotate-149 {
    transform: rotate(149deg); }
  .icon.rotate-150 {
    transform: rotate(150deg); }
  .icon.rotate-151 {
    transform: rotate(151deg); }
  .icon.rotate-152 {
    transform: rotate(152deg); }
  .icon.rotate-153 {
    transform: rotate(153deg); }
  .icon.rotate-154 {
    transform: rotate(154deg); }
  .icon.rotate-155 {
    transform: rotate(155deg); }
  .icon.rotate-156 {
    transform: rotate(156deg); }
  .icon.rotate-157 {
    transform: rotate(157deg); }
  .icon.rotate-158 {
    transform: rotate(158deg); }
  .icon.rotate-159 {
    transform: rotate(159deg); }
  .icon.rotate-160 {
    transform: rotate(160deg); }
  .icon.rotate-161 {
    transform: rotate(161deg); }
  .icon.rotate-162 {
    transform: rotate(162deg); }
  .icon.rotate-163 {
    transform: rotate(163deg); }
  .icon.rotate-164 {
    transform: rotate(164deg); }
  .icon.rotate-165 {
    transform: rotate(165deg); }
  .icon.rotate-166 {
    transform: rotate(166deg); }
  .icon.rotate-167 {
    transform: rotate(167deg); }
  .icon.rotate-168 {
    transform: rotate(168deg); }
  .icon.rotate-169 {
    transform: rotate(169deg); }
  .icon.rotate-170 {
    transform: rotate(170deg); }
  .icon.rotate-171 {
    transform: rotate(171deg); }
  .icon.rotate-172 {
    transform: rotate(172deg); }
  .icon.rotate-173 {
    transform: rotate(173deg); }
  .icon.rotate-174 {
    transform: rotate(174deg); }
  .icon.rotate-175 {
    transform: rotate(175deg); }
  .icon.rotate-176 {
    transform: rotate(176deg); }
  .icon.rotate-177 {
    transform: rotate(177deg); }
  .icon.rotate-178 {
    transform: rotate(178deg); }
  .icon.rotate-179 {
    transform: rotate(179deg); }
  .icon.rotate-180 {
    transform: rotate(180deg); }
  .icon.icon-loading {
    width: 22px;
    height: 22px;
    position: relative;
    margin: 10px; }
    .icon.icon-loading:before {
      position: absolute;
      content: '';
      top: -2px;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 500rem;
      border: 0.1em solid rgba(0, 0, 0, 0.1); }
    .icon.icon-loading:after {
      position: absolute;
      content: '';
      top: -2px;
      left: 0;
      width: 100%;
      height: 100%;
      animation: loader 0.6s linear;
      animation-iteration-count: infinite;
      border-radius: 500rem;
      border-color: #767676 transparent transparent;
      border-style: solid;
      border-width: 0.1em;
      box-shadow: 0 0 0 1px transparent; }
    .icon.icon-loading.inverted:before {
      border: 0.1em solid rgba(255, 255, 255, 0.3); }
    .icon.icon-loading.inverted:after {
      border-color: rgba(255, 255, 255, 0.6) transparent transparent; }

.icon-wrapper {
  width: 42px;
  height: 42px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  display: inline-block;
  vertical-align: middle;
  position: relative; }
  .icon-wrapper.border {
    border: 1px solid #231f20;
    border-radius: 100%; }
  .icon-wrapper.icon-link {
    cursor: pointer; }
  .icon-wrapper .icon {
    margin: 2px 0 0 0;
    position: absolute;
    transform: translateX(-50%);
    left: 50%; }

@keyframes loader {
  from {
    transform: rotate(0); }
  to {
    transform: rotate(359deg); } }

/* .link {
  font-family: font-family(text);
  color: color(violet);
  display: inline-block;
  position: relative;
  font-size: font-size();
  line-height: line-height();
  @extend .transition-fast;

  &.link-active {
    color: color(violet,lighten);
    text-decoration: none;
    &:hover {
      cursor: default;
      color: color(violet,lighten);
    }
  }

  &.link-disabled {
    color: color(white,darken, 2);
    cursor: default;
    &:hover {
      cursor: default;
      color: color(white, darken, 2);
    }
  }

  &:hover, &.link-hover {
    color: color(violet, darken);
  }
}
 */
.button, .input {
  background-color: transparent;
  border: none;
  color: #231f20;
  cursor: pointer;
  display: inline-block;
  font-family: pi-text, sans-serif;
  font-size: inherit;
  font-weight: normal;
  letter-spacing: 0.02em;
  line-height: inherit;
  margin: 0;
  overflow: hidden;
  padding: 0 35px;
  vertical-align: middle; }
  @media only screen and (max-width: 480px) {
    .button, .input {
      display: block;
      width: 100%; }
      .button + .button, .input + .button, .button + .input, .input + .input {
        margin-top: 28px; } }
  .button > .icon, .input > .icon {
    margin-right: 7px; }
  .button.center, .center.input {
    text-align: center; }
  .button.main, .main.input, .button.link, .link.input, .button.item-nav, .item-nav.input {
    padding: 0;
    /* vertical-align: bottom; */ }
  .button.item-nav, .item-nav.input {
    padding: 14px 0;
    color: #757378; }
  .button.link, .link.input {
    font-family: inherit;
    text-decoration: underline !important; }
  .button.main, .main.input {
    text-decoration: none;
    font-family: pi-text, sans-serif; }
  .button:hover, .input:hover, .button.hover, .hover.input {
    color: #757378; }
    .button:hover:before, .input:hover:before, .button.hover:before, .hover.input:before {
      opacity: 0.1; }
    .button:hover.item-nav, .input:hover.item-nav, .button.hover.item-nav, .hover.item-nav.input {
      color: #231f20; }
  .button:active, .input:active, .button.active, .active.input {
    color: #757378; }
    .button:active:before, .input:active:before, .button.active:before, .active.input:before {
      opacity: 0.1; }
    .button:active.item-nav, .input:active.item-nav, .button.active.item-nav, .active.item-nav.input {
      color: #231f20; }
  .button:disabled, .input:disabled, .button.disabled, .disabled.input {
    color: #c8c8d3;
    cursor: default; }
    .button:disabled:hover:before, .input:disabled:hover:before, .button.disabled:hover:before, .disabled.input:hover:before {
      opacity: 0.4; }
    .button:disabled.item-nav, .input:disabled.item-nav, .button.disabled.item-nav, .disabled.item-nav.input {
      color: #c8c8d3; }
  .button.negative, .negative.input {
    color: #fff; }
    .button.negative:hover, .negative.input:hover {
      color: #ebebf6; }
  .button.fluid, .fluid.input {
    display: block;
    text-align: center;
    box-sizing: border-box;
    width: 100%; }
    .button.fluid + .button, .fluid.input + .button, .button.fluid + .input, .fluid.input + .input {
      margin-top: 28px; }
  .button.blue, .blue.input {
    color: #017fbd; }
    .button.blue.disabled, .blue.disabled.input {
      color: rgba(1, 127, 189, 0.5);
      cursor: default; }
    .button.blue.secondary, .blue.secondary.input {
      color: #fff;
      background-color: #017fbd; }
      .button.blue.secondary.group + .group, .blue.secondary.group.input + .group {
        margin-left: -2px; }
      .button.blue.secondary:before, .blue.secondary.input:before {
        display: none; }
      .button.blue.secondary:hover, .blue.secondary.input:hover, .button.blue.secondary.hover, .blue.secondary.hover.input {
        background-color: #114e6e;
        color: #fff;
        z-index: 9; }
      .button.blue.secondary:active, .blue.secondary.input:active, .button.blue.secondary.active, .blue.secondary.active.input {
        background-color: rgba(17, 78, 110, 0.5); }
      .button.blue.secondary:disabled, .blue.secondary.input:disabled, .button.blue.secondary.disabled, .blue.secondary.disabled.input {
        background-color: #c8c8d3; }
        .button.blue.secondary:disabled:hover:before, .blue.secondary.input:disabled:hover:before, .button.blue.secondary.disabled:hover:before, .blue.secondary.disabled.input:hover:before {
          opacity: 0.4; }
    .button.blue:hover, .blue.input:hover, .button.blue.hover, .blue.hover.input {
      color: #114e6e; }
    .button.blue:disabled, .blue.input:disabled, .button.blue.disabled, .blue.disabled.input {
      color: #f5f5fa;
      background-color: #c8c8d3; }
      .button.blue:disabled:hover:before, .blue.input:disabled:hover:before, .button.blue.disabled:hover:before, .blue.disabled.input:hover:before {
        opacity: 0.4; }
  .button.red, .red.input {
    color: #ec576b; }
    .button.red.disabled, .red.disabled.input {
      color: rgba(236, 87, 107, 0.5);
      cursor: default; }
    .button.red.secondary, .red.secondary.input {
      color: #fff;
      background-color: #ec576b; }
      .button.red.secondary.group + .group, .red.secondary.group.input + .group {
        margin-left: -2px; }
      .button.red.secondary:before, .red.secondary.input:before {
        display: none; }
      .button.red.secondary:hover, .red.secondary.input:hover, .button.red.secondary.hover, .red.secondary.hover.input {
        background-color: #873a45;
        color: #fff;
        z-index: 9; }
      .button.red.secondary:active, .red.secondary.input:active, .button.red.secondary.active, .red.secondary.active.input {
        background-color: rgba(135, 58, 69, 0.5); }
      .button.red.secondary:disabled, .red.secondary.input:disabled, .button.red.secondary.disabled, .red.secondary.disabled.input {
        background-color: #c8c8d3; }
        .button.red.secondary:disabled:hover:before, .red.secondary.input:disabled:hover:before, .button.red.secondary.disabled:hover:before, .red.secondary.disabled.input:hover:before {
          opacity: 0.4; }
    .button.red:hover, .red.input:hover, .button.red.hover, .red.hover.input {
      color: #873a45; }
    .button.red:disabled, .red.input:disabled, .button.red.disabled, .red.disabled.input {
      color: #f5f5fa;
      background-color: #c8c8d3; }
      .button.red:disabled:hover:before, .red.input:disabled:hover:before, .button.red.disabled:hover:before, .red.disabled.input:hover:before {
        opacity: 0.4; }
  .button.violet, .violet.input {
    color: #6757ec; }
    .button.violet.disabled, .violet.disabled.input {
      color: rgba(103, 87, 236, 0.5);
      cursor: default; }
    .button.violet.secondary, .violet.secondary.input {
      color: #fff;
      background-color: #6757ec; }
      .button.violet.secondary.group + .group, .violet.secondary.group.input + .group {
        margin-left: -2px; }
      .button.violet.secondary:before, .violet.secondary.input:before {
        display: none; }
      .button.violet.secondary:hover, .violet.secondary.input:hover, .button.violet.secondary.hover, .violet.secondary.hover.input {
        background-color: #453b85;
        color: #fff;
        z-index: 9; }
      .button.violet.secondary:active, .violet.secondary.input:active, .button.violet.secondary.active, .violet.secondary.active.input {
        background-color: rgba(69, 59, 133, 0.5); }
      .button.violet.secondary:disabled, .violet.secondary.input:disabled, .button.violet.secondary.disabled, .violet.secondary.disabled.input {
        background-color: #c8c8d3; }
        .button.violet.secondary:disabled:hover:before, .violet.secondary.input:disabled:hover:before, .button.violet.secondary.disabled:hover:before, .violet.secondary.disabled.input:hover:before {
          opacity: 0.4; }
    .button.violet:hover, .violet.input:hover, .button.violet.hover, .violet.hover.input {
      color: #453b85; }
    .button.violet:disabled, .violet.input:disabled, .button.violet.disabled, .violet.disabled.input {
      color: #f5f5fa;
      background-color: #c8c8d3; }
      .button.violet:disabled:hover:before, .violet.input:disabled:hover:before, .button.violet.disabled:hover:before, .violet.disabled.input:hover:before {
        opacity: 0.4; }
  .button.aqua, .aqua.input {
    color: #01b8c8; }
    .button.aqua.disabled, .aqua.disabled.input {
      color: rgba(1, 184, 200, 0.5);
      cursor: default; }
    .button.aqua.secondary, .aqua.secondary.input {
      color: #fff;
      background-color: #01b8c8; }
      .button.aqua.secondary.group + .group, .aqua.secondary.group.input + .group {
        margin-left: -2px; }
      .button.aqua.secondary:before, .aqua.secondary.input:before {
        display: none; }
      .button.aqua.secondary:hover, .aqua.secondary.input:hover, .button.aqua.secondary.hover, .aqua.secondary.hover.input {
        background-color: #116b74;
        color: #fff;
        z-index: 9; }
      .button.aqua.secondary:active, .aqua.secondary.input:active, .button.aqua.secondary.active, .aqua.secondary.active.input {
        background-color: rgba(17, 107, 116, 0.5); }
      .button.aqua.secondary:disabled, .aqua.secondary.input:disabled, .button.aqua.secondary.disabled, .aqua.secondary.disabled.input {
        background-color: #c8c8d3; }
        .button.aqua.secondary:disabled:hover:before, .aqua.secondary.input:disabled:hover:before, .button.aqua.secondary.disabled:hover:before, .aqua.secondary.disabled.input:hover:before {
          opacity: 0.4; }
    .button.aqua:hover, .aqua.input:hover, .button.aqua.hover, .aqua.hover.input {
      color: #116b74; }
    .button.aqua:disabled, .aqua.input:disabled, .button.aqua.disabled, .aqua.disabled.input {
      color: #f5f5fa;
      background-color: #c8c8d3; }
      .button.aqua:disabled:hover:before, .aqua.input:disabled:hover:before, .button.aqua.disabled:hover:before, .aqua.disabled.input:hover:before {
        opacity: 0.4; }
  .button.green, .green.input {
    color: #01ba92; }
    .button.green.disabled, .green.disabled.input {
      color: rgba(1, 186, 146, 0.5);
      cursor: default; }
    .button.green.secondary, .green.secondary.input {
      color: #fff;
      background-color: #01ba92; }
      .button.green.secondary.group + .group, .green.secondary.group.input + .group {
        margin-left: -2px; }
      .button.green.secondary:before, .green.secondary.input:before {
        display: none; }
      .button.green.secondary:hover, .green.secondary.input:hover, .button.green.secondary.hover, .green.secondary.hover.input {
        background-color: #126c58;
        color: #fff;
        z-index: 9; }
      .button.green.secondary:active, .green.secondary.input:active, .button.green.secondary.active, .green.secondary.active.input {
        background-color: rgba(18, 108, 88, 0.5); }
      .button.green.secondary:disabled, .green.secondary.input:disabled, .button.green.secondary.disabled, .green.secondary.disabled.input {
        background-color: #c8c8d3; }
        .button.green.secondary:disabled:hover:before, .green.secondary.input:disabled:hover:before, .button.green.secondary.disabled:hover:before, .green.secondary.disabled.input:hover:before {
          opacity: 0.4; }
    .button.green:hover, .green.input:hover, .button.green.hover, .green.hover.input {
      color: #126c58; }
    .button.green:disabled, .green.input:disabled, .button.green.disabled, .green.disabled.input {
      color: #f5f5fa;
      background-color: #c8c8d3; }
      .button.green:disabled:hover:before, .green.input:disabled:hover:before, .button.green.disabled:hover:before, .green.disabled.input:hover:before {
        opacity: 0.4; }
  .button.white, .white.input {
    color: #f5f5fa; }
    .button.white.disabled, .white.disabled.input {
      color: rgba(245, 245, 250, 0.5);
      cursor: default; }
    .button.white.secondary, .white.secondary.input {
      color: #fff;
      background-color: #f5f5fa; }
      .button.white.secondary.group + .group, .white.secondary.group.input + .group {
        margin-left: -2px; }
      .button.white.secondary:before, .white.secondary.input:before {
        display: none; }
      .button.white.secondary:hover, .white.secondary.input:hover, .button.white.secondary.hover, .white.secondary.hover.input {
        background-color: #ebebf6;
        color: #fff;
        z-index: 9; }
      .button.white.secondary:active, .white.secondary.input:active, .button.white.secondary.active, .white.secondary.active.input {
        background-color: rgba(235, 235, 246, 0.5); }
      .button.white.secondary:disabled, .white.secondary.input:disabled, .button.white.secondary.disabled, .white.secondary.disabled.input {
        background-color: #c8c8d3; }
        .button.white.secondary:disabled:hover:before, .white.secondary.input:disabled:hover:before, .button.white.secondary.disabled:hover:before, .white.secondary.disabled.input:hover:before {
          opacity: 0.4; }
    .button.white:hover, .white.input:hover, .button.white.hover, .white.hover.input {
      color: #ebebf6; }
    .button.white:disabled, .white.input:disabled, .button.white.disabled, .white.disabled.input {
      color: #f5f5fa;
      background-color: #c8c8d3; }
      .button.white:disabled:hover:before, .white.input:disabled:hover:before, .button.white.disabled:hover:before, .white.disabled.input:hover:before {
        opacity: 0.4; }
  .button.black, .black.input {
    color: #231f20; }
    .button.black.disabled, .black.disabled.input {
      color: rgba(35, 31, 32, 0.5);
      cursor: default; }
    .button.black.secondary, .black.secondary.input {
      color: #fff;
      background-color: #231f20; }
      .button.black.secondary.group + .group, .black.secondary.group.input + .group {
        margin-left: -2px; }
      .button.black.secondary:before, .black.secondary.input:before {
        display: none; }
      .button.black.secondary:hover, .black.secondary.input:hover, .button.black.secondary.hover, .black.secondary.hover.input {
        background-color: #000;
        color: #fff;
        z-index: 9; }
      .button.black.secondary:active, .black.secondary.input:active, .button.black.secondary.active, .black.secondary.active.input {
        background-color: rgba(0, 0, 0, 0.5); }
      .button.black.secondary:disabled, .black.secondary.input:disabled, .button.black.secondary.disabled, .black.secondary.disabled.input {
        background-color: #c8c8d3; }
        .button.black.secondary:disabled:hover:before, .black.secondary.input:disabled:hover:before, .button.black.secondary.disabled:hover:before, .black.secondary.disabled.input:hover:before {
          opacity: 0.4; }
    .button.black:hover, .black.input:hover, .button.black.hover, .black.hover.input {
      color: #757378; }
    .button.black:disabled, .black.input:disabled, .button.black.disabled, .black.disabled.input {
      color: #f5f5fa;
      background-color: #c8c8d3; }
      .button.black:disabled:hover:before, .black.input:disabled:hover:before, .button.black.disabled:hover:before, .black.disabled.input:hover:before {
        opacity: 0.4; }
  .button.nav-item, .nav-item.input {
    height: 84px;
    line-height: 84px;
    font-size: 1rem;
    padding: 0 14px; }
    .button.nav-item .icon, .nav-item.input .icon {
      margin: 0px; }
  .button.xsmall, .xsmall.input {
    font-size: 12px;
    text-transform: uppercase; }
  .button.fill, .fill.input {
    padding: 0 35px;
    height: 56px;
    font-size: 1rem;
    line-height: 56px;
    text-decoration: none;
    border-radius: 28px;
    color: #fff;
    background-color: #6757ec;
    text-align: center; }
    .button.fill.no-horizontall-padded, .fill.no-horizontall-padded.input {
      padding-left: 0px;
      padding-right: 0px; }
    .button.fill.group:not(:last-child), .fill.group.input:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .button.fill.group + .group, .fill.group.input + .group {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
    .button.fill:hover, .fill.input:hover, .button.fill.hover, .fill.hover.input {
      background-color: #453b85;
      color: #f5f5fa;
      box-shadow: 0 0 1px 1px rgba(200, 200, 211, 0.3), 28px 28px 28px 0 rgba(200, 200, 211, 0.2); }
      .button.fill:hover:before, .fill.input:hover:before, .button.fill.hover:before, .fill.hover.input:before {
        opacity: 0.1; }
    .button.fill:active, .fill.input:active, .button.fill.active, .fill.active.input {
      color: rgba(255, 255, 255, 0.5);
      background-color: #453b85;
      box-shadow: 0 0 1px 1px rgba(200, 200, 211, 0.3), 28px 28px 28px 0 rgba(200, 200, 211, 0.2); }
      .button.fill:active:before, .fill.input:active:before, .button.fill.active:before, .fill.active.input:before {
        opacity: 0.1; }
    .button.fill:disabled, .fill.input:disabled, .button.fill.disabled, .fill.disabled.input {
      background-color: #f5f5fa;
      color: #c8c8d3;
      box-shadow: none;
      cursor: default; }
      .button.fill:disabled:hover:before, .fill.input:disabled:hover:before, .button.fill.disabled:hover:before, .fill.disabled.input:hover:before {
        opacity: 0.4; }
    .button.fill:before, .fill.input:before {
      border-radius: 28px !important; }
    .button.fill.secondary, .fill.secondary.input {
      border: 2px solid #6757ec;
      background-color: #fff;
      color: #6757ec;
      line-height: 54px; }
      .button.fill.secondary:before, .fill.secondary.input:before {
        display: none; }
      .button.fill.secondary:hover, .fill.secondary.input:hover, .button.fill.secondary.hover, .fill.secondary.hover.input {
        color: #453b85;
        border-color: #453b85;
        box-shadow: 0 0 1px 1px rgba(200, 200, 211, 0.3), 28px 28px 28px 0 rgba(200, 200, 211, 0.2); }
        .button.fill.secondary:hover:before, .fill.secondary.input:hover:before, .button.fill.secondary.hover:before, .fill.secondary.hover.input:before {
          opacity: 0; }
      .button.fill.secondary:active, .fill.secondary.input:active, .button.fill.secondary.active, .fill.secondary.active.input {
        color: rgba(69, 59, 133, 0.5);
        border-color: #453b85;
        box-shadow: 0 0 1px 1px rgba(200, 200, 211, 0.3), 28px 28px 28px 0 rgba(200, 200, 211, 0.2); }
        .button.fill.secondary:active:before, .fill.secondary.input:active:before, .button.fill.secondary.active:before, .fill.secondary.active.input:before {
          opacity: 0.1; }
      .button.fill.secondary:disabled, .fill.secondary.input:disabled, .button.fill.secondary.disabled, .fill.secondary.disabled.input {
        border-color: #c8c8d3;
        color: #c8c8d3;
        box-shadow: none;
        cursor: default; }
        .button.fill.secondary:disabled:hover:before, .fill.secondary.input:disabled:hover:before, .button.fill.secondary.disabled:hover:before, .fill.secondary.disabled.input:hover:before {
          opacity: 0.4; }
      .button.fill.secondary.negative, .fill.secondary.negative.input {
        background-color: transparent !important;
        color: #fff;
        border-color: #fff;
        box-shadow: none; }
        .button.fill.secondary.negative:before, .fill.secondary.negative.input:before {
          opacity: 0; }
        .button.fill.secondary.negative:hover:before, .fill.secondary.negative.input:hover:before, .button.fill.secondary.negative.hover:before, .fill.secondary.negative.hover.input:before {
          opacity: 0.2; }
        .button.fill.secondary.negative:active, .fill.secondary.negative.input:active, .button.fill.secondary.negative.active, .fill.secondary.negative.active.input {
          color: rgba(255, 255, 255, 0.5); }
    .button.fill.blue, .fill.blue.input {
      background-color: #017fbd; }
      .button.fill.blue.secondary, .fill.blue.secondary.input {
        border: 2px solid #017fbd;
        background-color: #fff;
        color: #017fbd; }
        .button.fill.blue.secondary:hover, .fill.blue.secondary.input:hover, .button.fill.blue.secondary.hover, .fill.blue.secondary.hover.input {
          color: #114e6e;
          border-color: #114e6e;
          background-color: #fff; }
        .button.fill.blue.secondary:active, .fill.blue.secondary.input:active, .button.fill.blue.secondary.active, .fill.blue.secondary.active.input {
          color: rgba(17, 78, 110, 0.5);
          border-color: #114e6e; }
        .button.fill.blue.secondary:disabled, .fill.blue.secondary.input:disabled, .button.fill.blue.secondary.disabled, .fill.blue.secondary.disabled.input {
          border-color: #c8c8d3;
          color: #c8c8d3;
          box-shadow: none;
          cursor: default; }
          .button.fill.blue.secondary:disabled:hover:before, .fill.blue.secondary.input:disabled:hover:before, .button.fill.blue.secondary.disabled:hover:before, .fill.blue.secondary.disabled.input:hover:before {
            opacity: 0.4; }
      .button.fill.blue:hover, .fill.blue.input:hover, .button.fill.blue.hover, .fill.blue.hover.input {
        background-color: #114e6e; }
      .button.fill.blue:disabled, .fill.blue.input:disabled, .button.fill.blue.disabled, .fill.blue.disabled.input {
        background-color: #f5f5fa;
        color: #c8c8d3;
        box-shadow: none;
        cursor: default; }
        .button.fill.blue:disabled:hover:before, .fill.blue.input:disabled:hover:before, .button.fill.blue.disabled:hover:before, .fill.blue.disabled.input:hover:before {
          opacity: 0.4; }
      .button.fill.blue.negative, .fill.blue.negative.input {
        background-color: #fff;
        box-shadow: 0 0 1px 1px rgba(200, 200, 211, 0.3), 14px 14px 14px 0 rgba(35, 31, 32, 0.1);
        color: #017fbd; }
        .button.fill.blue.negative:hover, .fill.blue.negative.input:hover, .button.fill.blue.negative.hover, .fill.blue.negative.hover.input {
          background-color: #f5f5fa;
          color: #114e6e; }
        .button.fill.blue.negative:active, .fill.blue.negative.input:active, .button.fill.blue.negative.active, .fill.blue.negative.active.input {
          background-color: #ebebf6; }
    .button.fill.red, .fill.red.input {
      background-color: #ec576b; }
      .button.fill.red.secondary, .fill.red.secondary.input {
        border: 2px solid #ec576b;
        background-color: #fff;
        color: #ec576b; }
        .button.fill.red.secondary:hover, .fill.red.secondary.input:hover, .button.fill.red.secondary.hover, .fill.red.secondary.hover.input {
          color: #873a45;
          border-color: #873a45;
          background-color: #fff; }
        .button.fill.red.secondary:active, .fill.red.secondary.input:active, .button.fill.red.secondary.active, .fill.red.secondary.active.input {
          color: rgba(135, 58, 69, 0.5);
          border-color: #873a45; }
        .button.fill.red.secondary:disabled, .fill.red.secondary.input:disabled, .button.fill.red.secondary.disabled, .fill.red.secondary.disabled.input {
          border-color: #c8c8d3;
          color: #c8c8d3;
          box-shadow: none;
          cursor: default; }
          .button.fill.red.secondary:disabled:hover:before, .fill.red.secondary.input:disabled:hover:before, .button.fill.red.secondary.disabled:hover:before, .fill.red.secondary.disabled.input:hover:before {
            opacity: 0.4; }
      .button.fill.red:hover, .fill.red.input:hover, .button.fill.red.hover, .fill.red.hover.input {
        background-color: #873a45; }
      .button.fill.red:disabled, .fill.red.input:disabled, .button.fill.red.disabled, .fill.red.disabled.input {
        background-color: #f5f5fa;
        color: #c8c8d3;
        box-shadow: none;
        cursor: default; }
        .button.fill.red:disabled:hover:before, .fill.red.input:disabled:hover:before, .button.fill.red.disabled:hover:before, .fill.red.disabled.input:hover:before {
          opacity: 0.4; }
      .button.fill.red.negative, .fill.red.negative.input {
        background-color: #fff;
        box-shadow: 0 0 1px 1px rgba(200, 200, 211, 0.3), 14px 14px 14px 0 rgba(35, 31, 32, 0.1);
        color: #ec576b; }
        .button.fill.red.negative:hover, .fill.red.negative.input:hover, .button.fill.red.negative.hover, .fill.red.negative.hover.input {
          background-color: #f5f5fa;
          color: #873a45; }
        .button.fill.red.negative:active, .fill.red.negative.input:active, .button.fill.red.negative.active, .fill.red.negative.active.input {
          background-color: #ebebf6; }
    .button.fill.violet, .fill.violet.input {
      background-color: #6757ec; }
      .button.fill.violet.secondary, .fill.violet.secondary.input {
        border: 2px solid #6757ec;
        background-color: #fff;
        color: #6757ec; }
        .button.fill.violet.secondary:hover, .fill.violet.secondary.input:hover, .button.fill.violet.secondary.hover, .fill.violet.secondary.hover.input {
          color: #453b85;
          border-color: #453b85;
          background-color: #fff; }
        .button.fill.violet.secondary:active, .fill.violet.secondary.input:active, .button.fill.violet.secondary.active, .fill.violet.secondary.active.input {
          color: rgba(69, 59, 133, 0.5);
          border-color: #453b85; }
        .button.fill.violet.secondary:disabled, .fill.violet.secondary.input:disabled, .button.fill.violet.secondary.disabled, .fill.violet.secondary.disabled.input {
          border-color: #c8c8d3;
          color: #c8c8d3;
          box-shadow: none;
          cursor: default; }
          .button.fill.violet.secondary:disabled:hover:before, .fill.violet.secondary.input:disabled:hover:before, .button.fill.violet.secondary.disabled:hover:before, .fill.violet.secondary.disabled.input:hover:before {
            opacity: 0.4; }
      .button.fill.violet:hover, .fill.violet.input:hover, .button.fill.violet.hover, .fill.violet.hover.input {
        background-color: #453b85; }
      .button.fill.violet:disabled, .fill.violet.input:disabled, .button.fill.violet.disabled, .fill.violet.disabled.input {
        background-color: #f5f5fa;
        color: #c8c8d3;
        box-shadow: none;
        cursor: default; }
        .button.fill.violet:disabled:hover:before, .fill.violet.input:disabled:hover:before, .button.fill.violet.disabled:hover:before, .fill.violet.disabled.input:hover:before {
          opacity: 0.4; }
      .button.fill.violet.negative, .fill.violet.negative.input {
        background-color: #fff;
        box-shadow: 0 0 1px 1px rgba(200, 200, 211, 0.3), 14px 14px 14px 0 rgba(35, 31, 32, 0.1);
        color: #6757ec; }
        .button.fill.violet.negative:hover, .fill.violet.negative.input:hover, .button.fill.violet.negative.hover, .fill.violet.negative.hover.input {
          background-color: #f5f5fa;
          color: #453b85; }
        .button.fill.violet.negative:active, .fill.violet.negative.input:active, .button.fill.violet.negative.active, .fill.violet.negative.active.input {
          background-color: #ebebf6; }
    .button.fill.aqua, .fill.aqua.input {
      background-color: #01b8c8; }
      .button.fill.aqua.secondary, .fill.aqua.secondary.input {
        border: 2px solid #01b8c8;
        background-color: #fff;
        color: #01b8c8; }
        .button.fill.aqua.secondary:hover, .fill.aqua.secondary.input:hover, .button.fill.aqua.secondary.hover, .fill.aqua.secondary.hover.input {
          color: #116b74;
          border-color: #116b74;
          background-color: #fff; }
        .button.fill.aqua.secondary:active, .fill.aqua.secondary.input:active, .button.fill.aqua.secondary.active, .fill.aqua.secondary.active.input {
          color: rgba(17, 107, 116, 0.5);
          border-color: #116b74; }
        .button.fill.aqua.secondary:disabled, .fill.aqua.secondary.input:disabled, .button.fill.aqua.secondary.disabled, .fill.aqua.secondary.disabled.input {
          border-color: #c8c8d3;
          color: #c8c8d3;
          box-shadow: none;
          cursor: default; }
          .button.fill.aqua.secondary:disabled:hover:before, .fill.aqua.secondary.input:disabled:hover:before, .button.fill.aqua.secondary.disabled:hover:before, .fill.aqua.secondary.disabled.input:hover:before {
            opacity: 0.4; }
      .button.fill.aqua:hover, .fill.aqua.input:hover, .button.fill.aqua.hover, .fill.aqua.hover.input {
        background-color: #116b74; }
      .button.fill.aqua:disabled, .fill.aqua.input:disabled, .button.fill.aqua.disabled, .fill.aqua.disabled.input {
        background-color: #f5f5fa;
        color: #c8c8d3;
        box-shadow: none;
        cursor: default; }
        .button.fill.aqua:disabled:hover:before, .fill.aqua.input:disabled:hover:before, .button.fill.aqua.disabled:hover:before, .fill.aqua.disabled.input:hover:before {
          opacity: 0.4; }
      .button.fill.aqua.negative, .fill.aqua.negative.input {
        background-color: #fff;
        box-shadow: 0 0 1px 1px rgba(200, 200, 211, 0.3), 14px 14px 14px 0 rgba(35, 31, 32, 0.1);
        color: #01b8c8; }
        .button.fill.aqua.negative:hover, .fill.aqua.negative.input:hover, .button.fill.aqua.negative.hover, .fill.aqua.negative.hover.input {
          background-color: #f5f5fa;
          color: #116b74; }
        .button.fill.aqua.negative:active, .fill.aqua.negative.input:active, .button.fill.aqua.negative.active, .fill.aqua.negative.active.input {
          background-color: #ebebf6; }
    .button.fill.green, .fill.green.input {
      background-color: #01ba92; }
      .button.fill.green.secondary, .fill.green.secondary.input {
        border: 2px solid #01ba92;
        background-color: #fff;
        color: #01ba92; }
        .button.fill.green.secondary:hover, .fill.green.secondary.input:hover, .button.fill.green.secondary.hover, .fill.green.secondary.hover.input {
          color: #126c58;
          border-color: #126c58;
          background-color: #fff; }
        .button.fill.green.secondary:active, .fill.green.secondary.input:active, .button.fill.green.secondary.active, .fill.green.secondary.active.input {
          color: rgba(18, 108, 88, 0.5);
          border-color: #126c58; }
        .button.fill.green.secondary:disabled, .fill.green.secondary.input:disabled, .button.fill.green.secondary.disabled, .fill.green.secondary.disabled.input {
          border-color: #c8c8d3;
          color: #c8c8d3;
          box-shadow: none;
          cursor: default; }
          .button.fill.green.secondary:disabled:hover:before, .fill.green.secondary.input:disabled:hover:before, .button.fill.green.secondary.disabled:hover:before, .fill.green.secondary.disabled.input:hover:before {
            opacity: 0.4; }
      .button.fill.green:hover, .fill.green.input:hover, .button.fill.green.hover, .fill.green.hover.input {
        background-color: #126c58; }
      .button.fill.green:disabled, .fill.green.input:disabled, .button.fill.green.disabled, .fill.green.disabled.input {
        background-color: #f5f5fa;
        color: #c8c8d3;
        box-shadow: none;
        cursor: default; }
        .button.fill.green:disabled:hover:before, .fill.green.input:disabled:hover:before, .button.fill.green.disabled:hover:before, .fill.green.disabled.input:hover:before {
          opacity: 0.4; }
      .button.fill.green.negative, .fill.green.negative.input {
        background-color: #fff;
        box-shadow: 0 0 1px 1px rgba(200, 200, 211, 0.3), 14px 14px 14px 0 rgba(35, 31, 32, 0.1);
        color: #01ba92; }
        .button.fill.green.negative:hover, .fill.green.negative.input:hover, .button.fill.green.negative.hover, .fill.green.negative.hover.input {
          background-color: #f5f5fa;
          color: #126c58; }
        .button.fill.green.negative:active, .fill.green.negative.input:active, .button.fill.green.negative.active, .fill.green.negative.active.input {
          background-color: #ebebf6; }
    .button.fill.white, .fill.white.input {
      background-color: #f5f5fa; }
      .button.fill.white.secondary, .fill.white.secondary.input {
        border: 2px solid #f5f5fa;
        background-color: #fff;
        color: #f5f5fa; }
        .button.fill.white.secondary:hover, .fill.white.secondary.input:hover, .button.fill.white.secondary.hover, .fill.white.secondary.hover.input {
          color: #ebebf6;
          border-color: #ebebf6;
          background-color: #fff; }
        .button.fill.white.secondary:active, .fill.white.secondary.input:active, .button.fill.white.secondary.active, .fill.white.secondary.active.input {
          color: rgba(235, 235, 246, 0.5);
          border-color: #ebebf6; }
        .button.fill.white.secondary:disabled, .fill.white.secondary.input:disabled, .button.fill.white.secondary.disabled, .fill.white.secondary.disabled.input {
          border-color: #c8c8d3;
          color: #c8c8d3;
          box-shadow: none;
          cursor: default; }
          .button.fill.white.secondary:disabled:hover:before, .fill.white.secondary.input:disabled:hover:before, .button.fill.white.secondary.disabled:hover:before, .fill.white.secondary.disabled.input:hover:before {
            opacity: 0.4; }
      .button.fill.white:hover, .fill.white.input:hover, .button.fill.white.hover, .fill.white.hover.input {
        background-color: #ebebf6; }
      .button.fill.white:disabled, .fill.white.input:disabled, .button.fill.white.disabled, .fill.white.disabled.input {
        background-color: #f5f5fa;
        color: #c8c8d3;
        box-shadow: none;
        cursor: default; }
        .button.fill.white:disabled:hover:before, .fill.white.input:disabled:hover:before, .button.fill.white.disabled:hover:before, .fill.white.disabled.input:hover:before {
          opacity: 0.4; }
      .button.fill.white.negative, .fill.white.negative.input {
        background-color: #fff;
        box-shadow: 0 0 1px 1px rgba(200, 200, 211, 0.3), 14px 14px 14px 0 rgba(35, 31, 32, 0.1);
        color: #f5f5fa; }
        .button.fill.white.negative:hover, .fill.white.negative.input:hover, .button.fill.white.negative.hover, .fill.white.negative.hover.input {
          background-color: #f5f5fa;
          color: #ebebf6; }
        .button.fill.white.negative:active, .fill.white.negative.input:active, .button.fill.white.negative.active, .fill.white.negative.active.input {
          background-color: #ebebf6; }
    .button.fill.black, .fill.black.input {
      background-color: #231f20; }
      .button.fill.black.secondary, .fill.black.secondary.input {
        border: 2px solid #231f20;
        background-color: #fff;
        color: #231f20; }
        .button.fill.black.secondary:hover, .fill.black.secondary.input:hover, .button.fill.black.secondary.hover, .fill.black.secondary.hover.input {
          color: #000;
          border-color: #000;
          background-color: #fff; }
        .button.fill.black.secondary:active, .fill.black.secondary.input:active, .button.fill.black.secondary.active, .fill.black.secondary.active.input {
          color: rgba(0, 0, 0, 0.5);
          border-color: #000; }
        .button.fill.black.secondary:disabled, .fill.black.secondary.input:disabled, .button.fill.black.secondary.disabled, .fill.black.secondary.disabled.input {
          border-color: #c8c8d3;
          color: #c8c8d3;
          box-shadow: none;
          cursor: default; }
          .button.fill.black.secondary:disabled:hover:before, .fill.black.secondary.input:disabled:hover:before, .button.fill.black.secondary.disabled:hover:before, .fill.black.secondary.disabled.input:hover:before {
            opacity: 0.4; }
      .button.fill.black:hover, .fill.black.input:hover, .button.fill.black.hover, .fill.black.hover.input {
        background-color: #000; }
      .button.fill.black:disabled, .fill.black.input:disabled, .button.fill.black.disabled, .fill.black.disabled.input {
        background-color: #f5f5fa;
        color: #c8c8d3;
        box-shadow: none;
        cursor: default; }
        .button.fill.black:disabled:hover:before, .fill.black.input:disabled:hover:before, .button.fill.black.disabled:hover:before, .fill.black.disabled.input:hover:before {
          opacity: 0.4; }
      .button.fill.black.negative, .fill.black.negative.input {
        background-color: #fff;
        box-shadow: 0 0 1px 1px rgba(200, 200, 211, 0.3), 14px 14px 14px 0 rgba(35, 31, 32, 0.1);
        color: #231f20; }
        .button.fill.black.negative:hover, .fill.black.negative.input:hover, .button.fill.black.negative.hover, .fill.black.negative.hover.input {
          background-color: #f5f5fa;
          color: #000; }
        .button.fill.black.negative:active, .fill.black.negative.input:active, .button.fill.black.negative.active, .fill.black.negative.active.input {
          background-color: #ebebf6; }
    .button.fill.level-0, .fill.level-0.input {
      box-shadow: 0 0 0 0 rgba(200, 200, 211, 0.2); }
    .button.fill.level-1, .fill.level-1.input {
      box-shadow: 0 0 1px 1px rgba(200, 200, 211, 0.3), 7px 7px 7px 0 rgba(200, 200, 211, 0.2); }
    .button.fill.level-2, .fill.level-2.input {
      box-shadow: 0 0 1px 1px rgba(200, 200, 211, 0.3), 14px 14px 14px 0 rgba(200, 200, 211, 0.2); }
    .button.fill.level-3, .fill.level-3.input {
      box-shadow: 0 0 1px 1px rgba(200, 200, 211, 0.3), 28px 28px 28px 0 rgba(200, 200, 211, 0.2); }
  .button + .link, .input + .link {
    margin-left: 28px; }
    @media only screen and (max-width: 820px) {
      .button + .link, .input + .link {
        margin-top: 21px;
        margin-left: 0;
        display: block; } }

.input {
  padding: 14px;
  cursor: text;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  .input.group {
    border: 0;
    margin: 0;
    width: auto !important; }

form textarea.input,
form input[type='text'],
form input[type='email'],
form input[type='password'] {
  border: 1px solid #c8c8d3;
  font-family: Open Sans, sans-serif;
  font-size: 1rem;
  margin: 0;
  margin-bottom: 21px;
  outline: none;
  padding: 14px;
  width: 100%; }
  form textarea.input.error,
  form input[type='text'].error,
  form input[type='email'].error,
  form input[type='password'].error {
    border-color: #ec576b !important; }

.input-legend.error {
  color: #ec576b;
  font-size: 12px;
  margin-bottom: 14px; }

.form-legend.error {
  color: #ec576b;
  font-size: 12px;
  margin: 14px 0;
  border: 1px solid #ec576b;
  border-left: none;
  border-right: none;
  padding: 7px 0; }

form label {
  font-family: pi-text, sans-serif; }

form .inline {
  display: flex; }
  form .inline > *:not(:last-child) {
    padding-right: 14px; }

.checkbox,
.radio {
  margin: 0;
  padding: 0; }
  .checkbox li,
  .checkbox .item,
  .radio li,
  .radio .item {
    border-radius: 7px;
    display: flex;
    padding: 7px 7px 7px 35px;
    position: relative; }
    .checkbox li > .legend,
    .checkbox .item > .legend,
    .radio li > .legend,
    .radio .item > .legend {
      font-size: 12px;
      min-width: 200px;
      text-align: right;
      text-transform: uppercase; }
    .checkbox li .body,
    .checkbox .item .body,
    .radio li .body,
    .radio .item .body {
      flex-grow: 1;
      padding-right: 28px; }
    .checkbox li.error,
    .checkbox .item.error,
    .radio li.error,
    .radio .item.error {
      background-color: rgba(245, 171, 181, 0.2);
      color: #873a45; }
      .checkbox li.error .legend,
      .checkbox .item.error .legend,
      .radio li.error .legend,
      .radio .item.error .legend {
        color: #ec576b; }
    .checkbox li.success,
    .checkbox .item.success,
    .radio li.success,
    .radio .item.success {
      background-color: rgba(128, 220, 200, 0.2);
      color: #126c58; }
      .checkbox li.success .legend,
      .checkbox .item.success .legend,
      .radio li.success .legend,
      .radio .item.success .legend {
        color: #01ba92; }
    .checkbox li.has-hover,
    .checkbox .item.has-hover,
    .radio li.has-hover,
    .radio .item.has-hover {
      cursor: pointer; }
    .checkbox li:before,
    .checkbox .item:before,
    .radio li:before,
    .radio .item:before {
      background-color: #fff;
      border: 2px solid #c8c8d3;
      border-radius: 50%;
      content: '';
      height: 20px;
      left: 7px;
      position: absolute;
      top: 11px;
      width: 20px; }
    .checkbox li:after,
    .checkbox .item:after,
    .radio li:after,
    .radio .item:after {
      border-radius: 50%;
      content: '';
      height: 10px;
      left: 12px;
      position: absolute;
      top: 16px;
      transition: all 0.2s;
      width: 10px; }
    .checkbox li.has-hover:hover:after,
    .checkbox .item.has-hover:hover:after,
    .radio li.has-hover:hover:after,
    .radio .item.has-hover:hover:after {
      background-color: #4d4a4c; }
    .checkbox li.checked:after,
    .checkbox .item.checked:after,
    .radio li.checked:after,
    .radio .item.checked:after {
      background-color: #01ba92; }
    .checkbox li.error.checked:after,
    .checkbox .item.error.checked:after,
    .radio li.error.checked:after,
    .radio .item.error.checked:after {
      background-color: #ec576b; }
  @media screen and (max-width: 1024px) {
    .checkbox,
    .radio {
      border: none; } }

input[type='checkbox'],
input[type='radio'] {
  display: inline-block;
  height: 42px;
  margin: 0;
  padding: 7px 7px 7px 35px;
  position: relative;
  cursor: default;
  appearance: none;
  vertical-align: middle;
  border: none; }
  input[type='checkbox']:before,
  input[type='radio']:before {
    background-color: #fff;
    border: 2px solid #c8c8d3;
    content: '';
    height: 20px;
    left: 7px;
    position: absolute;
    top: 11px;
    width: 20px; }
  input[type='checkbox']:after,
  input[type='radio']:after {
    content: '';
    height: 10px;
    left: 12px;
    position: absolute;
    top: 16px;
    transition: all 0.2s;
    width: 10px; }
  input[type='checkbox']:not(:disabled),
  input[type='radio']:not(:disabled) {
    cursor: pointer; }
    input[type='checkbox']:not(:disabled):hover:after,
    input[type='radio']:not(:disabled):hover:after {
      background-color: #c8c8d3; }
  input[type='checkbox']:checked:after,
  input[type='radio']:checked:after {
    background-color: #01ba92 !important; }
  input[type='checkbox'].red:checked:after,
  input[type='radio'].red:checked:after {
    background-color: #ec576b !important; }

input[type='radio']:after, input[type='radio']:before,
.radiobutton:after,
.radiobutton:before {
  border-radius: 50% !important; }

.card {
  background-color: #fff;
  box-shadow: 0 0 1px 1px rgba(200, 200, 211, 0.3), 14px 14px 14px 0 rgba(200, 200, 211, 0.2);
  margin: 21px 0;
  padding: 42px; }
  .card .container {
    padding: 0; }
  .card .card-info {
    border-right: 2px solid #f5f5fa; }
    .card .card-info .title {
      font-family: pi-display, sans-serif;
      margin-top: 0; }
    .card .card-info .title,
    .card .card-info h2 {
      margin-right: 35px; }
    .card .card-info h3 {
      margin-right: 35px;
      max-width: 98%;
      hyphens: auto; }
    .card .card-info .description {
      margin-top: 0;
      padding-right: 35px; }
  .card .card-metadata {
    display: flex;
    flex-direction: column;
    padding-bottom: 7px;
    padding-top: 7px; }
    .card .card-metadata .metadata {
      color: #4d4a4c;
      flex-grow: 1;
      font-family: pi-text, sans-serif; }
      .card .card-metadata .metadata .type {
        display: flex;
        align-items: center; }
    .card .card-metadata .avatar {
      margin-bottom: 0;
      margin-left: 7px;
      margin-top: 56px;
      width: 100%; }
    .card .card-metadata .name,
    .card .card-metadata .position,
    .card .card-metadata .institute {
      white-space: normal; }
  .card .actions {
    padding: 21px 0 14px; }
    @media only screen and (max-width: 480px) {
      .card .actions {
        text-align: center; } }
  .card.linked {
    overflow: hidden;
    position: relative; }
    @media only screen and (max-width: 1170px) {
      .card.linked:before {
        opacity: 1 !important; }
      .card.linked:after {
        right: 0 !important; } }
    .card.linked .arrow {
      height: 100%;
      opacity: 0;
      padding-right: 14px;
      padding-top: 14px;
      position: absolute;
      text-align: right;
      width: 100%;
      z-index: 10; }
      .card.linked .arrow:after {
        background-color: #6757ec;
        bottom: 0;
        content: '';
        height: 100%;
        position: absolute;
        right: -7px;
        width: 7px;
        z-index: 11; }
    .card.linked > .linked {
      bottom: 0;
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 9; }
      .card.linked > .linked .link-info .info {
        display: none; }
    .card.linked:hover {
      box-shadow: 0 0 1px 1px rgba(200, 200, 211, 0.3), 28px 28px 28px 0 rgba(200, 200, 211, 0.2); }
      .card.linked:hover .arrow {
        opacity: 1; }
        .card.linked:hover .arrow::after {
          right: 0; }
  .card.size-small {
    padding: 21px 42px 42px !important; }
    .card.size-small .metadata {
      padding-bottom: 7px; }
      .card.size-small .metadata .credits {
        font-family: pi-text, sans-serif; }
        .card.size-small .metadata .credits .icon {
          margin-right: -14px; }
      .card.size-small .metadata .type .icon {
        margin-left: -7px; }
    .card.size-small .card-info .title {
      font-family: pi-text, sans-serif;
      margin-top: 0; }
    .card.size-small .avatar {
      margin: 0; }
    .card.size-small .actions {
      padding: 14px 0 14px; }
    @media all and (max-width: 480px) {
      .card.size-small {
        padding: 21px !important; } }
  .card.info {
    box-shadow: 0 0 1px 1px rgba(200, 200, 211, 0.3), 7px 7px 7px 0 rgba(200, 200, 211, 0.2);
    padding: 28px; }
    .card.info .description {
      margin-bottom: 0;
      padding-bottom: 0; }

.cards .card .library-metadata .head {
  display: flex;
  flex-direction: column; }
  .cards .card .library-metadata .head .title {
    color: #4d4a4c;
    font-size: 14.4px;
    font-family: Open Sans, sans-serif;
    line-height: 1.16667rem; }
  .cards .card .library-metadata .head .earn-label {
    position: relative;
    right: 14px;
    margin-top: 7px;
    width: max-content; }
    .cards .card .library-metadata .head .earn-label span.icon {
      color: #6757ec; }
    .cards .card .library-metadata .head .earn-label .value {
      color: #6757ec;
      font-size: 18px;
      line-height: 24px;
      font-family: pi-text, sans-serif; }
    .cards .card .library-metadata .head .earn-label.expired .value,
    .cards .card .library-metadata .head .earn-label.expired span.icon {
      color: #c8c8d3; }

.cards .card {
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  height: 500px;
  margin: 0 14px 28px 14px;
  padding: 42px;
  vertical-align: middle;
  width: calc(33.3333% - 28px); }
  @media all and (max-width: 1202px) {
    .cards .card {
      width: calc(50% - 28px); } }
  @media all and (max-width: 780px) {
    .cards .card {
      width: calc(100% - 28px); } }
  .cards .card.linked .arrow {
    bottom: 0;
    padding: 0 7px 0 0;
    right: 0; }
    .cards .card.linked .arrow::after {
      height: 56px; }
  .cards .card.linked .linked {
    overflow: hidden; }
    .cards .card.linked .linked .link-info {
      background-color: #fff;
      bottom: -56px;
      box-shadow: 0 -4px 4px 0 rgba(35, 31, 32, 0.2);
      color: #757378;
      display: block;
      font-family: Open Sans, sans-serif;
      font-size: 12px;
      height: 56px;
      line-height: 56px;
      opacity: 0;
      padding-left: 7px;
      position: absolute;
      width: 100%; }
      @media all and (max-width: 1170px) {
        .cards .card.linked .linked .link-info {
          bottom: 0; } }
      .cards .card.linked .linked .link-info .info {
        display: block; }
    .cards .card.linked .linked:hover .link-info {
      bottom: 0;
      opacity: 1; }
      .cards .card.linked .linked:hover .link-info .arrow {
        opacity: 1; }
  .cards .card .metadata {
    border-bottom: 1px solid #ebebf6;
    display: flex;
    margin-bottom: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
    .cards .card .metadata .disabled {
      color: #ebebf6;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
      .cards .card .metadata .disabled path {
        fill: #ebebf6; }
    .cards .card .metadata .credits {
      max-width: 98px;
      display: flex;
      align-items: center; }
      .cards .card .metadata .credits p, .cards .card .metadata .credits .card-metadata .metadata, .cards .card .card-metadata .metadata .credits .metadata {
        font-size: 0.8rem;
        line-height: 1.2em;
        padding: 0;
        text-align: right; }
      .cards .card .metadata .credits .icon {
        position: relative;
        top: 2px; }
  .cards .card .library-metadata {
    border: none !important;
    margin-bottom: 21px;
    display: flex;
    align-items: flex-start; }
    .cards .card .library-metadata .credits p, .cards .card .library-metadata .credits .card-metadata .metadata, .cards .card .card-metadata .library-metadata .credits .metadata {
      line-height: 1.16667rem; }
  .cards .card .avatar {
    width: 100%; }
  .cards .card .card-info {
    border-right: 0;
    flex-grow: 1;
    margin-bottom: 28px;
    max-height: 100%;
    overflow: hidden;
    position: relative; }
    .cards .card .card-info .dimmer {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 80%, #fff 100%);
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
  .cards .card.PUBLICATION_INTERVIEW h4, .cards .card.PUBLICATION_INTERVIEW .card-info .title {
    font-family: pi-display, sans-serif; }

.cards.s50 .card {
  width: calc(50% - 28px); }

@media all and (max-width: 480px) {
  .cards .card {
    width: 100%;
    margin: 0 0 21px; } }

.avatar {
  margin-bottom: 2rem;
  text-align: center;
  line-height: 1.55556rem; }
  .avatar .info {
    overflow: hidden; }
    .avatar .info > * {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%; }
  .avatar .avatar_image {
    background-color: #c8c8d3; }
    .avatar .avatar_image .icon {
      color: #757378;
      left: 50%;
      margin-top: 4px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%); }
  .avatar .avatar_image,
  .avatar .avatar_image > img {
    border-radius: 100%;
    display: inline-flex;
    height: 98px;
    margin: 0 0 28px 0;
    min-width: 98px;
    position: relative;
    width: 98px; }
  .avatar .name {
    color: #4d4a4c;
    font-family: pi-text, sans-serif; }
  .avatar .legend,
  .avatar .data {
    color: #757378;
    font-size: 14.4px; }
    .avatar .legend li,
    .avatar .data li {
      font-size: 1rem;
      display: inline; }
    .avatar .legend ul,
    .avatar .data ul {
      margin: 0;
      padding-left: 0; }
    .avatar .legend li:after,
    .avatar .data li:after {
      content: ', '; }
    .avatar .legend li:last-child:after,
    .avatar .data li:last-child:after {
      content: ''; }
  .avatar.linked {
    display: block;
    cursor: pointer; }
  .avatar.inline {
    display: flex;
    flex-direction: columns;
    align-items: center;
    text-align: left; }
    .avatar.inline .avatar_image,
    .avatar.inline .avatar_image > img {
      margin: 0 28px 0 0; }
    .avatar.inline.inverted {
      text-align: right; }
      .avatar.inline.inverted .avatar_image,
      .avatar.inline.inverted .avatar_image > img {
        margin: 0 0 0 28px; }
  .avatar.b .avatar_image,
  .avatar.b .avatar_image > img {
    height: 150px;
    margin: 0 0 28px;
    max-width: 150px;
    width: 150px; }
  .avatar.s {
    line-height: 1.16667rem; }
    .avatar.s .name {
      font-size: 14.4px; }
    .avatar.s .legend,
    .avatar.s .data {
      font-size: 12px; }
    .avatar.s .position {
      display: none; }
    .avatar.s .avatar_image,
    .avatar.s .avatar_image > img {
      margin: 0 0 28px 0;
      width: 56px;
      min-width: 56px;
      height: 56px; }
    .avatar.s.inline .avatar_image,
    .avatar.s.inline .avatar_image > img {
      margin: 0 14px 0 0; }
    .avatar.s.inline.inverted {
      text-align: right; }
      .avatar.s.inline.inverted .avatar_image {
        margin: 0 0 0 14px; }
        .avatar.s.inline.inverted .avatar_image > img {
          margin: 0; }
  .avatar.xs {
    line-height: 0.97222rem; }
    .avatar.xs .name {
      font-size: 12px; }
    .avatar.xs .legend,
    .avatar.xs .data {
      display: none; }
    .avatar.xs .position {
      display: none; }
    .avatar.xs .avatar_image,
    .avatar.xs .avatar_image > img {
      margin: 0 0 14px 0;
      width: 42px;
      min-width: 42px;
      height: 42px; }
    .avatar.xs.inline .avatar_image,
    .avatar.xs.inline .avatar_image > img {
      margin: 0 14px 0 0; }
    .avatar.xs.inline.inverted {
      text-align: right; }
      .avatar.xs.inline.inverted .avatar_image {
        margin: 0 0 0 14px; }
        .avatar.xs.inline.inverted .avatar_image > img {
          margin: 0; }

.segment {
  padding: 112px 0; }
  .segment.padded-horizontal {
    padding: 112px; }
  .segment.s {
    padding: 35px 0; }
    .segment.s.padded-horizontal {
      padding: 35px; }
  .segment.xs {
    padding: 28px 0; }
    .segment.xs.padded-horizontal {
      padding: 28px; }
  .segment.xxs {
    padding: 14px 0; }
    .segment.xxs.padded-horizontal {
      padding: 14px; }
  .segment.group + .group {
    padding-top: 0px; }
  .segment.white {
    background-color: #fff;
    color: #231f20; }
  .segment.black {
    background-color: #231f20;
    color: #fff; }
  .segment.aqua {
    background-color: #01b8c8;
    color: #fff; }
  .segment.blue {
    background-color: #017fbd;
    color: #fff; }
  .segment.violet {
    background-color: #6757ec;
    color: #fff; }
  .segment.green {
    background-color: #01ba92;
    color: #fff; }
  .segment.red {
    background-color: #ec576b;
    color: #fff; }
  @media only screen and (max-width: 480px) {
    .segment {
      padding: 56px 0; }
      .segment.s {
        padding: 28px 0; } }

@media all and (max-width: 1170px) {
  .layout-with-sidebar_topbar + div,
  .layout-full-width_topbar + div {
    margin-top: 84px; } }

body.section .zEWidget-launcher,
body.test .zEWidget-launcher {
  bottom: 90px !important; }

.notification-browser {
  background: lightgoldenrodyellow;
  font-size: 17px;
  padding: 10px;
  position: relative;
  text-align: center;
  z-index: 9999; }

.full-height {
  height: 100%; }
  .full-height #root {
    height: 100%; }

.psycho_logo {
  color: #231f20;
  font-family: pi-text, sans-serif;
  line-height: 84px;
  text-decoration: none;
  transition: all 0.2s; }
  .psycho_logo:hover, .psycho_logo:focus, .psycho_logo:active {
    color: #757378; }
  .psycho_logo .icon-logo_text {
    font-size: 52px; }

#root {
  align-items: strech;
  display: flex;
  flex-direction: column; }
  #root .scrollable {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative; }

.main-sidebar {
  position: inherit !important;
  overflow: inherit !important; }
  .main-sidebar .main-sidebar-block {
    background-color: #fff;
    max-width: 400px;
    width: 90%;
    z-index: 99999 !important; }
  .main-sidebar .overlay-sidebar {
    background-color: rgba(0, 0, 0, 0.8) !important;
    z-index: 88888 !important; }

.content-sidebar {
  height: 100vh;
  position: inherit !important; }

.Toastify {
  flex-grow: 0; }

.gradient-darken.gradient {
  position: relative; }
  .gradient-darken.gradient > * {
    z-index: 2; }
  .gradient-darken.gradient::after {
    background-color: rgba(0, 0, 0, 0.2);
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0; }

.gradient-darken .pricing-hero h1 {
  max-width: 100%;
  text-align: center; }

.Toastify .Toastify__toast-container {
  max-width: 460px;
  width: 100%;
  transition: all 0.2s; }
  .Toastify .Toastify__toast-container.Toastify__toast-container--top-center {
    max-width: 70%; }
  .Toastify .Toastify__toast-container .Toastify__toast {
    font-size: 14.4px;
    line-height: 1.16667rem;
    padding: 14px 56px;
    border-radius: 14px;
    border-radius: 14px; }
    @media all and (max-width: 480px) {
      .Toastify .Toastify__toast-container .Toastify__toast {
        padding: 14px;
        display: block;
        border-radius: 0; } }
    .Toastify .Toastify__toast-container .Toastify__toast .button, .Toastify .Toastify__toast-container .Toastify__toast .input {
      position: absolute;
      right: 14px;
      top: 14px; }
      @media all and (max-width: 480px) {
        .Toastify .Toastify__toast-container .Toastify__toast .button, .Toastify .Toastify__toast-container .Toastify__toast .input {
          position: static;
          margin-top: 14px; } }

footer {
  position: relative;
  z-index: 5; }
  footer .footer {
    color: #c8c8d3; }
    footer .footer p, footer .footer .card .card-metadata .metadata, .card .card-metadata footer .footer .metadata,
    footer .footer li,
    footer .footer h6,
    footer .footer .card.size-small .card-info .title,
    .card.size-small .card-info footer .footer .title {
      max-width: 100%; }
    
    footer .footer h6,
    footer .footer .card.size-small .card-info .title,
    .card.size-small .card-info footer .footer .title {
      color: #c8c8d3; }
    footer .footer .newsletter {
      margin-bottom: 35px; }
    footer .footer .line {
      border-top: 1px solid #4d4a4c;
      padding-top: 35px; }
      footer .footer .line img {
        margin-right: 14px;
        margin-bottom: -1px; }
    @media all and (max-width: 1024px) {
      footer .footer {
        padding-top: 42px;
        text-align: center; }
        footer .footer form {
          display: flex !important;
          justify-content: center !important; }
          footer .footer form button {
            display: inline-flex !important;
            flex-grow: 0 !important;
            margin: 0 !important;
            min-width: 56px !important;
            padding: 0 7px !important;
            width: auto !important; }
          footer .footer form input {
            flex-grow: 1 !important;
            max-width: 300px !important; } }
    footer .footer .nav {
      margin: 0 0 28px;
      padding: 0;
      flex-direction: column; }
      footer .footer .nav li::before {
        display: none; }
    footer .footer .newsletter {
      text-align: center; }
      @media all and (max-width: 1170px) {
        footer .footer .newsletter button {
          display: flex;
          align-items: center;
          justify-content: center; } }

.button.download-file span, .download-file.input span {
  text-transform: uppercase; }

img {
  max-width: 100%; }

.full-height .layout-full-width_content {
  align-items: stretch !important;
  flex-direction: row;
  justify-content: stretch !important; }
  @media all and (max-width: 820px) {
    .full-height .layout-full-width_content {
      display: flex !important;
      flex-direction: column; } }
  .full-height .layout-full-width_content #features h6, .full-height .layout-full-width_content #features .card.size-small .card-info .title, .card.size-small .card-info .full-height .layout-full-width_content #features .title {
    text-align: left; }
  .full-height .layout-full-width_content #features .included-text {
    color: #6757ec;
    margin: 42px 0 14px 0; }
    @media all and (max-width: 480px) {
      .full-height .layout-full-width_content #features .included-text {
        margin: 21px 0 14px 0; } }
  .full-height .layout-full-width_content #features .account-features {
    margin: 14px 0 56px 0; }
    .full-height .layout-full-width_content #features .account-features .item {
      color: #757378;
      font-family: Open Sans, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 21px;
      margin: 14px 0;
      text-align: left;
      display: flex; }
      .full-height .layout-full-width_content #features .account-features .item .check-img {
        margin-right: 14px; }
  .full-height .layout-full-width_content #form {
    flex-grow: 1 !important; }
    .full-height .layout-full-width_content #form h4, .full-height .layout-full-width_content #form .card .card-info .title, .card .card-info .full-height .layout-full-width_content #form .title {
      text-align: left; }
    .full-height .layout-full-width_content #form .create-free-account {
      text-align: center; }
      .full-height .layout-full-width_content #form .create-free-account .learn-more {
        color: #757378;
        font-family: Open Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 35px;
        margin-top: 35px; }
  .full-height .layout-full-width_content #left,
  .full-height .layout-full-width_content #right {
    flex-grow: 1 !important;
    padding: 56px 84px; }
    .full-height .layout-full-width_content #left.equal-width,
    .full-height .layout-full-width_content #right.equal-width {
      flex: 1; }
    @media all and (max-width: 1170px) {
      .full-height .layout-full-width_content #left,
      .full-height .layout-full-width_content #right {
        padding: 35px; } }
    @media all and (max-width: 480px) {
      .full-height .layout-full-width_content #left,
      .full-height .layout-full-width_content #right {
        text-align: center;
        padding: 21px; } }
  .full-height .layout-full-width_content #left {
    box-shadow: 0 0 1px 1px rgba(200, 200, 211, 0.3), 14px 14px 14px 0 rgba(200, 200, 211, 0.2);
    display: flex !important;
    flex-direction: column !important;
    max-width: 700px !important;
    min-width: 615px !important;
    position: relative !important; }
    .full-height .layout-full-width_content #left.equal-width {
      max-width: none !important; }
    @media all and (max-width: 820px) {
      .full-height .layout-full-width_content #left {
        order: 2; } }
    .full-height .layout-full-width_content #left .psycho_logo {
      padding: 0;
      position: absolute;
      top: 28px; }
      @media all and (max-width: 480px) {
        .full-height .layout-full-width_content #left .psycho_logo {
          display: inline-block;
          margin: auto;
          position: static;
          text-align: center; }
          .full-height .layout-full-width_content #left .psycho_logo + .segment {
            padding-bottom: 21px;
            padding-top: 21px; }
          .full-height .layout-full-width_content #left .psycho_logo .icon-logo_text {
            display: none;
            font-size: 42px; } }
    @media all and (max-width: 1170px) {
      .full-height .layout-full-width_content #left {
        max-width: 100% !important;
        min-width: 0 !important;
        width: 100% !important; } }
  @media all and (max-width: 820px) {
    .full-height .layout-full-width_content #right {
      order: 1; } }
