@import './src/resources/sass/ui/foundation';

$max-heigth: 490px;
$max-width: 847px;
$size-search: 80px;
$size-list: $max-heigth - $size-search;
$color-container: color(black, darken, 1);
$color-list: color(black);
$color-font: color(white, darken, 1);

#player {
  .container {
    background-color: $color-container;
    height: $max-heigth;
    width: $max-width;
    border-radius: 10px;
    color: $color-font;
    margin-top: spacing(14);
    margin-bottom: spacing(14);
  }

  .player-container {
    margin-top: spacing(14);
  }

  .description-container {
    margin-right: 0px;
    height: 210px;
    max-height: 210px;
    align-content: start;
    hr {
      width: 100%;
    }
  }

  .img {
    height: 50px;
    border-radius: 100px;
  }

  .title-container {
    margin-top: spacing(14);
    width: 100%;
  }

  .title {
    font-size: medium;
    text-align: justify;
    padding: 0;
  }

  .join-newsletter {
    box-shadow: 0 0 0 0;
    height: 30px;
    line-height: normal;
    font-size: smaller;
    font-weight: 100;
    width: max-content;
    margin: 0px;
    padding: 0px;
    margin-top: spacing(7);
    padding-left: spacing(14);
    padding-right: spacing(14);
  }

  .description {
    margin-top: spacing(14);
    font-size: smaller;
    line-height: normal;
    max-width: 100%;
    text-align: justify;
  }

  .row {
    height: 100%;
  }

  .list {
    background-color: $color-list;
    max-height: $size-list;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .grid-episode-item {
    width: 100%;
    text-align: left;
    padding: 0;
    font-size: smaller;
    line-height: normal !important;
    cursor: pointer;
  }

  .row-episode-item {
    align-items: center;
    margin: 0;
  }

  hr {
    border-color: color(black, lighten, 1);
  }

  .mt-14 {
    margin-top: spacing(14);
  }

  .container-search {
    height: $size-search;
    align-items: center;
    background-color: $color-list;
  }

  .input-search {
    width: 100%;
    background-color: color(black);
    height: 50px;
    border-radius: 49px;
    border-color: color(white, darken, 1);
    margin: spacing(14);
    padding-left: spacing(49);
    font-size: medium;
    color: $color-font;
  }

  .icon-search {
    position: absolute;
    padding-left: spacing(14);
    color: $color-font;
    padding-top: spacing(7);
  }

  .container-2nd-column {
    background-color: $color-list;
  }

  .player {
    padding-right: spacing(14);
  }

  //MOBILE
  @media all and (max-width: 1024px) {
    .container {
      width: 90% !important;
      height: $max-heigth * 2;
    }
  }

  ::-webkit-scrollbar {
    width: spacing(7);
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: color(black, lighten, 1);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: color(black, lighten, 2);
  }
}
