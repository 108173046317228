#calendar h6 {
  color: #757378; }

#calendar .calendar-list {
  padding-left: 0;
  padding-right: 0; }
  #calendar .calendar-list .calendar-item {
    border-bottom: 1px solid #c8c8d3; }
    @media all and (min-width: 820px) {
      #calendar .calendar-list .calendar-item {
        margin-bottom: 0; }
        #calendar .calendar-list .calendar-item + .calendar-item {
          border-top: none; } }
  #calendar .calendar-list .month {
    border-bottom: 1px solid #c8c8d3; }

#calendar nav.years div {
  border-bottom: 1px solid #c8c8d3;
  padding: 21px 0;
  font-family: pi-text, sans-serif;
  color: #c8c8d3;
  cursor: pointer; }
  #calendar nav.years div.active {
    color: #231f20; }
  #calendar nav.years div:hover, #calendar nav.years div:focus, #calendar nav.years div:active {
    color: #757378; }
  #calendar nav.years div:first-child {
    border-top: 1px solid #c8c8d3; }

@media all and (min-width: 820px) {
  #calendar .calendar-list {
    padding-left: inherit;
    padding-right: inherit; }
    #calendar .calendar-list > div > div > .month:nth-child(2) {
      border-top: 1px solid #c8c8d3; }
    #calendar .calendar-list .calendar-item {
      border: 1.5px solid #c8c8d3;
      border-top-width: 0;
      border-bottom-width: 1px; }
    #calendar .calendar-list .month {
      border-right: 2px solid #c8c8d3;
      border-left: 2px solid #c8c8d3; } }

#calendar .button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 35px; }
