.search-container {
  background-color: #fff;
  height: 100%;
  left: 0;
  position: fixed;
  width: 100%; }
  .search-container .content {
    margin: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .search-container .content .searchbar {
      width: 100%;
      margin-right: 14px; }
    .search-container .content .cancel-button {
      cursor: pointer;
      font-size: 14px;
      line-height: 19px;
      font-family: Open Sans, sans-serif; }
