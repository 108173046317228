$icomoon-font-family: 'pi-icons' !default;
$icomoon-font-path: 'fonts' !default;

$icon-audio_file: '\e92a';
$icon-video_file: '\e92b';
$icon-twitter: '\e92c';
$icon-instagram: '\e92d';
$icon-facebook: '\e92e';
$icon-linkedin: '\e92f';
$icon-presentation_file: '\e930';
$icon-help: '\e91c';
$icon-category: '\e923';
$icon-amex: '\e924';
$icon-mastercard: '\e925';
$icon-visa: '\e926';
$icon-menu: '\e927';
$icon-PUBLICATION_QUICK_TAKE: '\e928';
$icon-PUBLICATION_CAP_ST: '\e928';
$icon-logo_full: '\e922';
$icon-logo_text: '\e921';
$icon-account: '\e909';
$icon-arrow_up: '\e900';
$icon-arrow_down: '\e901';
$icon-arrow_left: '\e902';
$icon-arrow_right: '\e903';
$icon-chevron_right: '\e904';
$icon-chevron_down: '\e905';
$icon-chevron_up: '\e906';
$icon-paypal: '\e907';
$icon-stripe: '\e908';
$icon-notification: '\e90a';
$icon-calendar: '\e90b';
$icon-chevron_left: '\e90c';
$icon-sidebar: '\e90d';
$icon-saved: '\e90e';
$icon-save: '\e90f';
$icon-lock: '\e910';
$icon-download: '\e911';
$icon-info: '\e912';
$icon-time: '\e913';
$icon-cross: '\e914';
$icon-delete: '\e915';
$icon-check: '\e916';
$icon-completed: '\e917';
$icon-logo: '\e918';
$icon-search: '\e919';
$icon-certification: '\e91a';
$icon-OPEN_ACCESS: '\e91b';
$icon-research_review: '\e91d';
$icon-PODCAST: '\e91e';
$icon-PUBLICATION_VIDEO: '\e91f';
$icon-SELF_ASSESSMENT: '\e920';
$icon-PUBLICATION_INTERVIEW: '\e929';
