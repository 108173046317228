.mb-wrapper {
  position: relative;
  align-items: center;
  display: inline-flex;
  vertical-align: top;
  padding-left: 14px;
  cursor: pointer; }
  .mb-wrapper .inline {
    height: 84px; }
  .mb-wrapper .mb-button {
    margin-right: 35px;
    display: inline-block; }
  .mb-wrapper .mb-menu {
    top: 84px;
    right: 0px;
    width: 100%;
    position: absolute;
    background-color: #fff;
    min-width: 250px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1; }
    .mb-wrapper .mb-menu .mb-item {
      font-family: pi-text, sans-serif;
      padding: 6px;
      min-height: 54px;
      display: flex;
      align-items: center; }
      .mb-wrapper .mb-menu .mb-item .no-icon {
        margin-left: 7px; }
      .mb-wrapper .mb-menu .mb-item .icon.icon-account {
        margin-top: 3px;
        margin-bottom: -3px; }
      .mb-wrapper .mb-menu .mb-item:hover {
        opacity: 0.7; }
      .mb-wrapper .mb-menu .mb-item.separator {
        border-bottom: 1px solid #ebebf6; }

@media all and (max-width: 1170px) {
  .mb-wrapper .mb-button {
    margin-right: 14px; } }
