.select-wrapper {
  background-color: #fff;
  font-family: Open Sans, sans-serif !important;
  font-size: 1rem !important;
  margin-bottom: 14px !important; }
  .select-wrapper .select__control {
    border-color: #c8c8d3 !important;
    border-radius: 0;
    box-shadow: none;
    height: 57px; }
    .select-wrapper .select__control .select__value-container {
      padding: 0 14px; }
    .select-wrapper .select__control .select__indicators .select__indicator {
      padding: 14px !important; }
    .select-wrapper .select__control .select__indicators .select__indicator-separator {
      display: none; }
    .select-wrapper .select__control .select__input input {
      margin: 0 10px 0 0; }
