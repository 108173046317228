@media all and (max-width: 480px) {
  .contact-us p {
    font-size: 14.4px; }
  .contact-us h1 {
    font-size: 22.5px; } }

.contact-us .form {
  border: 1px solid #c8c8d3; }
  @media all and (max-width: 480px) {
    .contact-us .form {
      padding: 21px !important; } }
  .contact-us .form label {
    font-family: pi-text, sans-serif; }
  .contact-us .form input {
    resize: none; }
  .contact-us .form textarea {
    resize: vertical;
    min-height: 130px;
    max-height: 500px; }
