.async-hide {
  opacity: 0 !important; }

.update-version {
  height: 35px;
  font-size: small;
  margin-left: 14px;
  display: inline;
  align-items: center;
  color: #4d4a4c; }
  @media all and (max-width: 1170px) {
    .update-version {
      height: 21px;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 600;
      margin: 0;
      font-size: 12px;
      padding: 3px 0 0 7px;
      line-height: 0.97222rem;
      background: #fff; } }
  @media all and (max-width: 480px) {
    .update-version > span:nth-child(2) {
      display: none; } }
