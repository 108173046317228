.cta {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 84px;
  padding: 14px 28px;
  position: fixed;
  background: #fff;
  width: 100vw;
  z-index: 1000;
  top: -84px;
  left: 0;
  transition: top 0.3s; }
  .cta.show {
    top: 0;
    box-shadow: 0px 0px 21px 7px rgba(200, 200, 216, 0.5); }
  .cta.bottom {
    top: inherit;
    bottom: 0; }
  .cta > span {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0;
    cursor: pointer;
    overflow: hidden; }
  .cta.left > span {
    left: 0; }
  .cta.right > span {
    right: 0; }
  .cta .content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 756px; }
    .cta .content-wrapper :first-child {
      margin-right: 21px; }
    .cta .content-wrapper :second-child {
      margin-left: 21px; }
    @media all and (max-width: 480px) {
      .cta .content-wrapper {
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%; }
        .cta .content-wrapper button {
          font-weight: 500;
          font-size: 18px;
          line-height: 21px; }
        .cta .content-wrapper .cta-copy {
          margin-bottom: 7px;
          margin-right: 0; } }
    .cta .content-wrapper .cta-copy {
      display: flex;
      flex-direction: column; }
      .cta .content-wrapper .cta-copy .cta-copy-title {
        font-family: pi-text, sans-serif;
        color: #231f20;
        line-height: 28px; }
        @media all and (max-width: 480px) {
          .cta .content-wrapper .cta-copy .cta-copy-title {
            font-size: 16px;
            font-weight: 700;
            font-family: pi-display, sans-serif;
            margin-right: 0;
            max-width: 270px; } }
      .cta .content-wrapper .cta-copy .cta-copy-subtitle {
        font-family: Open Sans, sans-serif;
        font-size: 16px;
        color: #757378; }
        @media all and (max-width: 480px) {
          .cta .content-wrapper .cta-copy .cta-copy-subtitle {
            display: none; } }
      @media all and (max-width: 480px) {
        .cta .content-wrapper .cta-copy {
          align-self: flex-start; } }
    .cta .content-wrapper button {
      max-width: max-content; }
      @media all and (max-width: 480px) {
        .cta .content-wrapper button {
          width: initial;
          margin: 0; } }
  .cta #cta-close-button {
    position: absolute;
    right: 0; }
  @media (max-width: 480px) {
    .cta {
      height: 132px;
      top: -132px;
      padding: 14px 35px; }
      .cta #cta-close-button {
        top: 0; } }
