@import 'variables';

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-format-wv,
.icon-format-wma,
.icon-format-webm,
.icon-format-wav,
.icon-format-vox,
.icon-format-tta,
.icon-format-sln,
.icon-format-raw,
.icon-format-ra,
.icon-format-opus,
.icon-format-ogg,
.icon-format-nsf,
.icon-format-nmf,
.icon-format-msv,
.icon-format-mpc,
.icon-format-mp3,
.icon-format-mmf,
.icon-format-m4p,
.icon-format-m4b,
.icon-format-m4a,
.icon-format-ivs,
.icon-format-iklax,
.icon-format-gsm,
.icon-format-flac,
.icon-format-dvf,
.icon-format-dss,
.icon-format-dct,
.icon-format-awb,
.icon-format-au,
.icon-format-ape,
.icon-format-amr,
.icon-format-aiff,
.icon-format-act,
.icon-format-aax,
.icon-format-aac,
.icon-format-aa,
.icon-format-8svx,
.icon-format-3gp,
.icon-audio_file {
  &:before {
    content: $icon-audio_file;
  }
}

.icon-format-3gp,
.icon-format-3g2,
.icon-format-asf,
.icon-format-amv,
.icon-format-avi,
.icon-format-drc,
.icon-format-flv,
.icon-format-flv,
.icon-format-flv,
.icon-format-gif,
.icon-format-m4v,
.icon-format-mxf,
.icon-format-mkv,
.icon-format-mts,
.icon-format-mpg,
.icon-format-mpg,
.icon-format-mp4,
.icon-format-mng,
.icon-format-nsv,
.icon-format-ogv,
.icon-format-mov,
.icon-format-yuv,
.icon-format-rm,
.icon-format-rmvb,
.icon-format-roq,
.icon-format-svi,
.icon-format-gifv,
.icon-format-vob,
.icon-format-webm,
.icon-format-wmv,
.icon-video_file {
  &:before {
    content: $icon-video_file;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin;
  }
}

.icon-format-pdf,
.icon-format-docs,
.icon-format-docx,
.icon-format-docm,
.icon-format-xml,
.icon-format-dotx,
.icon-format-dotm,
.icon-format-ppt,
.icon-format-pptx,
.icon-format-pptm,
.icon-format-ppt,
.icon-format-ppt,
.icon-presentation_file {
  &:before {
    content: $icon-presentation_file;
  }
}
.icon-help {
  &:before {
    content: $icon-help;
  }
}
.icon-category {
  &:before {
    content: $icon-category;
  }
}
.icon-amex {
  &:before {
    content: $icon-amex;
  }
}
.icon-mastercard {
  &:before {
    content: $icon-mastercard;
  }
}
.icon-visa {
  &:before {
    content: $icon-visa;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
.icon-PUBLICATION_QUICK_TAKE {
  &:before {
    content: $icon-PUBLICATION_QUICK_TAKE;
  }
}
.icon-PUBLICATION_CAP_ST {
  &:before {
    content: $icon-PUBLICATION_CAP_ST;
  }
}
.icon-logo_full {
  &:before {
    content: $icon-logo_full;
  }
}
.icon-logo_text {
  &:before {
    content: $icon-logo_text;
  }
}
.icon-account {
  &:before {
    content: $icon-account;
  }
}
.icon-arrow_up {
  &:before {
    content: $icon-arrow_up;
  }
}
.icon-arrow_down {
  &:before {
    content: $icon-arrow_down;
  }
}
.icon-arrow_left {
  &:before {
    content: $icon-arrow_left;
  }
}
.icon-arrow_right {
  &:before {
    content: $icon-arrow_right;
  }
}
.icon-chevron_right {
  &:before {
    content: $icon-chevron_right;
  }
}
.icon-chevron_down {
  &:before {
    content: $icon-chevron_down;
  }
}
.icon-chevron_up {
  &:before {
    content: $icon-chevron_up;
  }
}
.icon-paypal {
  &:before {
    content: $icon-paypal;
  }
}
.icon-stripe {
  &:before {
    content: $icon-stripe;
  }
}
.icon-notification {
  &:before {
    content: $icon-notification;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon-chevron_left {
  &:before {
    content: $icon-chevron_left;
  }
}
.icon-sidebar {
  &:before {
    content: $icon-sidebar;
  }
}
.icon-saved {
  &:before {
    content: $icon-saved;
  }
}
.icon-save {
  &:before {
    content: $icon-save;
  }
}
.icon-lock {
  &:before {
    content: $icon-lock;
  }
}
.icon-download {
  &:before {
    content: $icon-download;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-time {
  &:before {
    content: $icon-time;
  }
}
.icon-cross {
  &:before {
    content: $icon-cross;
  }
}
.icon-delete {
  &:before {
    content: $icon-delete;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-completed {
  &:before {
    content: $icon-completed;
  }
}
.icon-logo {
  &:before {
    content: $icon-logo;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-certification {
  &:before {
    content: $icon-certification;
  }
}
.icon-OPEN_ACCESS {
  &:before {
    content: $icon-OPEN_ACCESS;
  }
}
.icon-research_review {
  &:before {
    content: $icon-research_review;
  }
}
.icon-PODCAST {
  &:before {
    content: $icon-PODCAST;
  }
}
.icon-PUBLICATION_VIDEO {
  &:before {
    content: $icon-PUBLICATION_VIDEO;
  }
}
.icon-SELF_ASSESSMENT {
  &:before {
    content: $icon-SELF_ASSESSMENT;
  }
}
.icon-PUBLICATION_INTERVIEW {
  &:before {
    content: $icon-PUBLICATION_INTERVIEW;
  }
}
