//SPACING
$block: 7px;

$spacing-map: (
  7: $block,
  14: $block * 2,
  21: $block * 3,
  28: $block * 4,
  35: $block * 5,
  42: $block * 6,
  49: $block * 7,
  56: $block * 8,
  84: $block * 12,
  98: $block * 14,
  112: $block * 16,
);

@function spacing($variant: 28) {
  @if type-of($variant) != number {
    @return 0;
  }
  $sign: if($variant < 0, -1, 1);
  $fetched-value: map-get($spacing-map, abs($variant));
  @if type-of($fetched-value) == number {
    @return $fetched-value * $sign;
  } @else {
    @error 'Spacing variant `#{$variant}` not found. Available variants: #{available-names($spacing-map)} or the same negative values';
  }
}
