// Need to define the raw colors somewhere!
// stylelint-disable color-no-hex, function-max-empty-lines, value-list-max-empty-lines
$color-map: (
  white: (
    base: #f5f5fa,
    darken: (
      1: #ebebf6,
      2: #c8c8d3,
    ),
    lighten: (
      1: #fff,
    ),
  ),
  black: (
    base: #231f20,
    lighten: (
      1: #4d4a4c,
      2: #757378,
    ),
    darken: (
      1: #000,
    ),
  ),
  aqua: (
    base: #01b8c8,
    darken: (
      1: #116b74,
    ),
    lighten: (
      1: #7fdbe3,
    ),
  ),
  blue: (
    base: #017fbd,
    darken: (
      1: #114e6e,
    ),
    lighten: (
      1: #7fbedd,
    ),
  ),
  violet: (
    base: #6757ec,
    darken: (
      1: #453b85,
    ),
    lighten: (
      1: #b3abf5,
    ),
  ),
  green: (
    base: #01ba92,
    darken: (
      1: #126c58,
    ),
    lighten: (
      1: #80dcc8,
    ),
  ),
  red: (
    base: #ec576b,
    darken: (
      1: #873a45,
    ),
    lighten: (
      1: #f5abb5,
    ),
  ),
);

$colors: blue red violet aqua green white black;

/// Returns the color value for a given color name and group.
///
/// @param {String} $hue - The color's hue.
/// @param {String} $value - The darkness/lightness of the color. Defaults to base.
/// @param {Color} $for-background - The background color on which this color will
/// appear. Applies a multiply filter to ensure appropriate contrast.
/// @return {Color} The color value.

@function color($color, $shade: null, $value: 1) {
  @if $shade == null {
    $fetched-color: map-get(map-get($color-map, $color), base);
    @if map-has-key($color-map, $fetched-color) {
      $fetched-color: map-get(map-get($color-map, $fetched-color), base);
    }

    @if type-of($fetched-color) == color {
      @return $fetched-color;
    } @else {
      @error 'Color `#{$color} - #{$value}` not found. Available colors: #{available-names($color-map)}';
    }
  } @else {
    $color-hue: map-get(map-get($color-map, $color), $shade);
    @if map-has-key(map-get($color-map, $color), $shade) {
      $color-hue: map-get(map-get($color-map, $color), $shade);

      $fetched-color: map-get($color-hue, $value);

      @if map-has-key($color-hue, $fetched-color) {
        $fetched-color: map-get($color-hue, $value);
      }

      @if type-of($fetched-color) == color {
        @return $fetched-color;
      } @else {
        @error 'Value `#{$value}` not found. Available values for this shade: #{available-names($color-hue)}';
      }
    } @else {
      @error 'Shade `#{$shade}` not found. Available shades: #{available-names(map-get($color-map, $color))}';
    }
  }
}
