.rc-tooltip .rc-tooltip-content .rc-tooltip-inner {
  border-radius: 5px;
  font-size: 12px;
  max-width: 200px;
  padding: 10px 12px;
  text-align: center; }

.rc-tooltip.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  border-width: 0 5px 5px 0;
  left: 5px; }

.rc-tooltip.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  border-width: 0 0 5px 5px;
  right: 0; }

.rc-tooltip.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  border-width: 5px 5px 0 0;
  left: 5px; }

.rc-tooltip.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  border-width: 5px 0 0 5px;
  right: 0; }
