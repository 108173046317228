.layout-full-width_container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  width: 100%; }
  .layout-full-width_container .layout-full-width_topbar {
    display: block;
    flex-grow: 0;
    flex-direction: column; }
  .layout-full-width_container .layout-full-width_content {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 100%;
    width: 100%; }
  .layout-full-width_container.full-height .layout-full-width_content_container {
    display: flex;
    flex-grow: 1; }
    .layout-full-width_container.full-height .layout-full-width_content_container.loading {
      align-items: center;
      justify-content: center; }
