#podcast .hero .button {
  margin-right: 28px; }
  #podcast .hero .button.active {
    color: #114e6e; }

#podcast .hero img {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 1; }

#podcast .container-download-text {
  margin-bottom: 56px;
  margin-top: -21px;
  color: #4d4a4c;
  font-family: 'GTEestiProText';
  font-size: 28px;
  line-height: 35px;
  opacity: 0.7999999999999999;
  text-align: left; }

#podcast .title {
  margin-top: 0; }

#podcast .subtitle {
  opacity: 0.7;
  margin: 0;
  margin-bottom: 7px; }

#podcast .container-promotion {
  padding-bottom: 112px;
  padding-top: 98px;
  color: #4d4a4c; }
  @media all and (max-width: 480px) {
    #podcast .container-promotion {
      padding-bottom: 35px;
      padding-top: 42px; } }

@media all and (max-width: 480px) {
  #podcast .referrer {
    margin-bottom: 35px; } }

@media all and (max-width: 820px) {
  #podcast .title,
  #podcast .subtitle,
  #podcast .container-download-text,
  #podcast .promotion {
    text-align: center; } }

@media all and (max-width: 1170px) {
  #podcast .hero img {
    opacity: 0.2;
    max-width: 380px; } }
