.error-page h6,
.error-page h2 {
  max-width: 100%; }

.error-page h2 {
  margin: 0 0 56px; }

.error-page .hero {
  color: #f5f5fa;
  background-color: #231f20;
  padding: 112px 0;
  text-align: center; }
  .error-page .hero .button {
    box-shadow: 0 0 1px 1px rgba(200, 200, 211, 0.3), 14px 14px 14px 0 rgba(35, 31, 32, 0.1); }

.error-page .hide-timer {
  visibility: hidden; }
