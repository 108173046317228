.custom-nav {
  align-items: center;
  padding-right: 14px; }
  .custom-nav .wrapper-components {
    display: flex; }
  .custom-nav .internal-component {
    padding: 0 7px; }

.default-nav,
.custom-nav {
  width: 100%;
  display: flex;
  justify-content: space-between; }
  .default-nav .searchbar,
  .custom-nav .searchbar {
    margin: 0 14px 0 28px; }

.empty-nav {
  display: flex; }

.topbar,
.sidebarnav {
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 1px 1px rgba(200, 200, 211, 0.3), 7px 7px 7px 0 rgba(35, 31, 32, 0.1);
  display: flex;
  flex-grow: 0;
  padding: 0 0 0 28px;
  position: relative;
  z-index: 555 !important; }
  .topbar .col,
  .sidebarnav .col {
    display: flex;
    flex-grow: 0;
    position: relative; }
    .topbar .col.title,
    .sidebarnav .col.title {
      justify-content: center;
      left: 0;
      padding: 0 414px;
      top: 0;
      position: absolute;
      right: 0; }
      @media all and (max-width: 1170px) {
        .topbar .col.title,
        .sidebarnav .col.title {
          padding: 0 250px; } }
      .topbar .col.title a,
      .sidebarnav .col.title a {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
    .topbar .col .link,
    .sidebarnav .col .link {
      color: #231f20;
      font-family: pi-text, sans-serif;
      line-height: 84px;
      text-decoration: none;
      transition: all 0.2s; }
      .topbar .col .link:hover, .topbar .col .link:focus, .topbar .col .link:active,
      .sidebarnav .col .link:hover,
      .sidebarnav .col .link:focus,
      .sidebarnav .col .link:active {
        color: #757378; }
      .topbar .col .link.active,
      .sidebarnav .col .link.active {
        color: #c8c8d3; }
      .topbar .col .link.signin-topbar, .topbar .col .link.register,
      .sidebarnav .col .link.signin-topbar,
      .sidebarnav .col .link.register {
        color: #6757ec; }
        .topbar .col .link.signin-topbar:hover, .topbar .col .link.signin-topbar:focus, .topbar .col .link.signin-topbar:active, .topbar .col .link.register:hover, .topbar .col .link.register:focus, .topbar .col .link.register:active,
        .sidebarnav .col .link.signin-topbar:hover,
        .sidebarnav .col .link.signin-topbar:focus,
        .sidebarnav .col .link.signin-topbar:active,
        .sidebarnav .col .link.register:hover,
        .sidebarnav .col .link.register:focus,
        .sidebarnav .col .link.register:active {
          color: #b3abf5; }
      .topbar .col .link.become-a-member,
      .sidebarnav .col .link.become-a-member {
        color: #01b8c8;
        padding-right: 14px; }
        .topbar .col .link.become-a-member:hover, .topbar .col .link.become-a-member:focus, .topbar .col .link.become-a-member:active,
        .sidebarnav .col .link.become-a-member:hover,
        .sidebarnav .col .link.become-a-member:focus,
        .sidebarnav .col .link.become-a-member:active {
          color: #7fdbe3; }
    .topbar .col .nav,
    .sidebarnav .col .nav {
      align-items: center;
      display: flex; }
      .topbar .col .nav .link,
      .sidebarnav .col .nav .link {
        padding: 0 14px; }
        @media all and (min-width: 820px) {
          .topbar .col .nav .link,
          .sidebarnav .col .nav .link {
            max-height: 47px;
            line-height: 1.55556rem; } }
        .topbar .col .nav .link:last-child,
        .sidebarnav .col .nav .link:last-child {
          padding: 0 14px; }
    .topbar .col .nav-mask,
    .sidebarnav .col .nav-mask {
      height: 84px;
      width: 700px; }
    .topbar .col .avatar-user,
    .sidebarnav .col .avatar-user {
      margin-bottom: unset; }
    .topbar .col .label-membership,
    .sidebarnav .col .label-membership {
      box-shadow: 0 0 1px 1px rgba(200, 200, 211, 0.3), 7px 7px 7px 0 rgba(35, 31, 32, 0.1);
      color: #fff;
      cursor: pointer;
      display: inline;
      font-size: 12px;
      line-height: 22.5px;
      padding: 0 14px;
      position: absolute;
      right: 0;
      top: 0;
      transition: all 0.2s;
      z-index: 2; }
      .topbar .col .label-membership:hover,
      .sidebarnav .col .label-membership:hover {
        box-shadow: 0 0 1px 1px rgba(200, 200, 211, 0.3), 14px 14px 14px 0 rgba(35, 31, 32, 0.1); }
    .topbar .col.full-space,
    .sidebarnav .col.full-space {
      flex-grow: 1; }
    .topbar .col .divider,
    .sidebarnav .col .divider {
      border-left: 1px solid #c8c8d3;
      display: inline-block;
      height: 42px;
      padding: 0 14px 0 0; }

.sidebarnav {
  box-shadow: none;
  flex-direction: column;
  padding: 28px;
  text-align: center; }
  .sidebarnav .input-container .input-img {
    height: 17px;
    left: 0;
    margin: 0 14px;
    position: absolute;
    top: 38px;
    width: 17px; }
  .sidebarnav .input-container input {
    background: #f5f5fa;
    border-radius: 3px;
    border: 1px solid #c8c8d3;
    box-sizing: border-box;
    height: 51px;
    margin: 21px 0 14px 0;
    padding-left: 38px;
    width: 100%;
    font-size: 14px; }
  .sidebarnav .input-container input::placeholder {
    color: #757378;
    font-family: Open Sans, sans-serif; }
  .sidebarnav .input-container input:placeholder-shown {
    text-overflow: ellipsis; }
  .sidebarnav .col {
    display: block;
    width: 100%; }
  .sidebarnav .nav {
    display: flex;
    flex-direction: column; }
    .sidebarnav .nav .divider {
      display: none; }
    .sidebarnav .nav .link {
      border-bottom: 1px solid #ebebf6;
      display: block;
      margin: 0 !important;
      width: 100%; }
      .sidebarnav .nav .link:first-child {
        border-top: 1px solid #ebebf6; }
      .sidebarnav .nav .link:last-child {
        padding: 0 14px; }
      .sidebarnav .nav .link + .divider {
        margin-bottom: 42px; }
  @media all and (max-width: 480px) {
    .sidebarnav .psycho_logo .icon-logo_text {
      font-size: 42px !important; } }

@media all and (max-width: 1170px) {
  .topbar {
    left: 0;
    padding-left: 7px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9; } }

@media all and (max-width: 820px) {
  .sidebarnav .col .link {
    line-height: 49px; } }
