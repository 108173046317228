.link-edit-translation-wrapper .link-edit-translation {
  background: #333333;
  border-radius: 15px;
  color: white;
  font-family: sans;
  font-size: 12px;
  font-weight: 800 !important;
  line-height: 12px;
  opacity: 0.3;
  padding: 10px 20px;
  position: absolute;
  text-decoration: none;
  transition: 0.2s; }

.link-edit-translation-wrapper:hover .link-edit-translation {
  opacity: 1; }
