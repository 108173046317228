#referral-program .referral-input-container {
  display: flex;
  flex-direction: column; }
  #referral-program .referral-input-container > div {
    width: 100%; }

#referral-program .list-container .for-each-new-referral p {
  color: #757378;
  font-size: 14.4px; }

#referral-program .list-container .loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; }

#referral-program .list-container .line {
  border: 1px solid #ebebf6; }

#referral-program .list-container .row {
  display: flex;
  flex-direction: column;
  padding: 14px 0; }
  #referral-program .list-container .row .refered-email-accepted {
    font-family: Open Sans, sans-serif;
    font-size: 14.4px;
    font-weight: 400;
    line-height: 1.55556rem;
    text-align: left;
    color: #c7c7d2; }
  #referral-program .list-container .row .refered-email-pending {
    font-family: Open Sans, sans-serif;
    font-size: 14.4px;
    font-weight: 400;
    line-height: 1.55556rem;
    text-align: left;
    color: #757378;
    width: 313px; }
  #referral-program .list-container .row .sent {
    font-family: pi-text, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.55556rem;
    text-align: left;
    color: #01ba92; }
    #referral-program .list-container .row .sent .icon {
      visibility: hidden;
      font-size: 10px; }
  #referral-program .list-container .row .pending-resend {
    align-items: center;
    display: flex;
    flex-direction: row; }
    #referral-program .list-container .row .pending-resend .pending {
      font-family: pi-text, sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.55556rem;
      text-align: left;
      color: #c7c7d2;
      padding-right: 14px; }
    #referral-program .list-container .row .pending-resend .resend {
      font-family: pi-text, sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.55556rem;
      text-align: left;
      color: #6757ec;
      background-color: white;
      border-width: 0;
      cursor: pointer; }
  #referral-program .list-container .row .pending-to-send-gitfcard {
    font-family: pi-text, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.55556rem;
    text-align: left;
    color: #757378; }

@media all and (min-width: 820px) {
  #referral-program .list-container .row {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 50px;
    justify-content: space-between;
    padding: 14px 0; }
    #referral-program .list-container .row .refered-email-accepted {
      font-family: Open Sans, sans-serif;
      font-size: 14.4px;
      font-weight: 400;
      line-height: 1.55556rem;
      text-align: left;
      color: #c7c7d2;
      padding-top: 0; }
    #referral-program .list-container .row .refered-email-pending {
      font-family: Open Sans, sans-serif;
      font-size: 14.4px;
      font-weight: 400;
      line-height: 1.55556rem;
      text-align: left;
      color: #757378;
      width: 313px;
      padding-top: 0; }
    #referral-program .list-container .row .sent {
      font-family: pi-text, sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.55556rem;
      text-align: left;
      color: #01ba92;
      padding: 0; }
      #referral-program .list-container .row .sent .icon {
        visibility: visible;
        padding: 0;
        font-size: 35px; }
    #referral-program .list-container .row .pending-resend {
      display: flex;
      flex-direction: row;
      flex-flow: row-reverse;
      justify-content: space-between; }
      #referral-program .list-container .row .pending-resend .pending {
        font-family: pi-text, sans-serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.55556rem;
        text-align: left;
        color: #c7c7d2;
        text-align: right;
        padding-right: 0;
        padding-left: 35px; }
      #referral-program .list-container .row .pending-resend .resend {
        opacity: 0;
        text-align: left;
        padding: 0; }
    #referral-program .list-container .row .pending-to-send-gitfcard {
      text-align: right; }
  #referral-program .list-container .row:hover .resend {
    font-family: pi-text, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.55556rem;
    text-align: left;
    color: #6757ec;
    opacity: 1; }
  #referral-program .list-container .row:hover .resend {
    font-family: pi-text, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.55556rem;
    text-align: left;
    color: #6757ec;
    opacity: 1; } }

#referral-program .container-green-success {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around; }
  #referral-program .container-green-success .icon-wrapper {
    height: 70px;
    margin: 0 0 21px 0;
    width: 70px; }
    #referral-program .container-green-success .icon-wrapper .icon {
      position: relative;
      top: 15px; }
  #referral-program .container-green-success div {
    color: #fff;
    font-family: pi-text, sans-serif;
    font-size: 22.5px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    width: 100%; }
  @media all and (min-width: 820px) {
    #referral-program .container-green-success {
      flex-direction: row;
      justify-content: flex-start; }
      #referral-program .container-green-success .icon-wrapper {
        margin: 0 21px 0 0; }
      #referral-program .container-green-success div {
        text-align: left;
        width: 425px; } }

#referral-program .container-green {
  display: flex;
  flex-direction: column; }
  #referral-program .container-green .input-container {
    display: flex;
    flex-direction: column; }
    #referral-program .container-green .input-container > div {
      width: 100%; }
      #referral-program .container-green .input-container > div .refer-input {
        background-color: #f5f5fa;
        flex-grow: 2;
        font-family: Open Sans, sans-serif;
        margin-right: 0;
        margin-bottom: 7px;
        width: 100%; }
        @media all and (max-width: 480px) {
          #referral-program .container-green .input-container > div .refer-input {
            font-size: 14.4px; } }
      #referral-program .container-green .input-container > div .message {
        color: #fff;
        font-family: Open Sans, sans-serif;
        font-size: 14.4px;
        font-weight: 400;
        line-height: 21px;
        margin-bottom: 14px;
        text-align: left; }
    @media all and (min-width: 820px) {
      #referral-program .container-green .input-container {
        flex-direction: row; }
        #referral-program .container-green .input-container .button {
          position: relative;
          right: 25px;
          max-height: 55px; } }
  @media all and (min-width: 820px) {
    #referral-program .container-green .refer-input {
      margin-right: -40px;
      margin-bottom: 0; } }

#referral-program .check-invite-and-win {
  color: #757378;
  font-family: Open Sans, sans-serif;
  font-size: 14.4px;
  font-weight: 400;
  padding-left: 14px;
  padding-top: 14px;
  text-align: left;
  display: flex; }
  #referral-program .check-invite-and-win .button {
    background: transparent;
    border: none;
    color: #757378;
    padding: 0 7px;
    text-decoration: underline; }
    #referral-program .check-invite-and-win .button:hover {
      color: #231f20; }
  @media all and (max-width: 480px) {
    #referral-program .check-invite-and-win {
      flex-direction: row;
      width: max-content; } }

@media all and (max-width: 480px) {
  #referral-program .segment.padded-horizontal {
    padding: 21px; } }
