.cme-information .toggle-title h3 {
  margin: 0 auto 35px auto;
  text-align: center; }

.cme-information .toggle-content {
  max-width: 756px;
  margin: auto; }
  .cme-information .toggle-content > div {
    border-bottom: 1px solid #ebebf6;
    padding: 28px 0; }
  .cme-information .toggle-content > div:nth-child(1) {
    border-top: 1px solid #ebebf6; }
  .cme-information .toggle-content .toggle-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer; }
    .cme-information .toggle-content .toggle-title h6 {
      margin: 0;
      line-height: 1.94444rem;
      font-family: pi-text, sans-serif; }
  .cme-information .toggle-content .statements-content span {
    font-size: 14.4px;
    font-family: Open Sans, sans-serif; }
  .cme-information .toggle-content .statements-content h6 {
    margin-top: 49px;
    margin-bottom: 35px;
    max-width: 100%; }
  .cme-information .toggle-content .statements-content p {
    color: #4d4a4c;
    font-size: 1rem; }
