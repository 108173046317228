@import 'resources/sass/ui/foundation';

.loading {
  color: color(black, lighten, 2);
  font-size: font-size(s);

  &.centered {
    margin: spacing(56) 0;
    width: 100%;
    text-align: center;
    display: block;
  }
}
