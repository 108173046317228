.error-network .bg-grey {
  background-color: #f5f5fa; }

.error-network .center-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.error-network .content-container {
  background-color: #fff;
  width: 843px;
  min-height: 464px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .error-network .content-container .content {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around; }
    .error-network .content-container .content.final {
      height: 100%; }
    .error-network .content-container .content .try-text {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 35px; }
    .error-network .content-container .content .status-text {
      font-size: 28.125px;
      line-height: 1.94444rem;
      font-family: pi-display, sans-serif; }
    .error-network .content-container .content .clock,
    .error-network .content-container .content .status-text {
      margin-bottom: 7px; }
    .error-network .content-container .content .timer {
      color: #01b8c8; }
    .error-network .content-container .content .support {
      font-size: 12px; }
    .error-network .content-container .content .contact {
      line-height: 0; }
  .error-network .content-container .hero {
    color: #231f20;
    background-color: #f5f5fa;
    padding: 112px 0;
    text-align: center; }
    .error-network .content-container .hero .button {
      box-shadow: 0 0 1px 1px rgba(200, 200, 211, 0.3), 14px 14px 14px 0 rgba(35, 31, 32, 0.1); }
