@import 'src/resources/sass/ui/_foundation.scss';

.referrer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  margin-top: spacing(14);
  margin-bottom: spacing(112);

  &.clean {
    margin: 0;
  }
  a {
    margin-right: spacing(7);
  }
  img {
    height: 40px;
  }
}
