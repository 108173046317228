.membershipLabel {
  color: #6757ec;
  font-size: 16px;
  margin-left: 14px;
  text-align: start;
  text-decoration: underline; }
  @media all and (max-width: 480px) {
    .membershipLabel {
      text-align: center; } }

.month {
  border-bottom: 1px solid #c8c8d3;
  height: 35px; }
  .month h6 {
    padding: 7px 14px; }

.calendar-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  padding: 21px 7px;
  border: none; }
  .calendar-item > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-self: flex-start; }
    .calendar-item > div:nth-child(1) .title-author-content {
      display: flex;
      flex-direction: column;
      margin-left: 14px; }
      .calendar-item > div:nth-child(1) .title-author-content h6 {
        margin-bottom: 0;
        margin-top: 7px;
        font-family: pi-text, sans-serif;
        max-width: 780px; }
      .calendar-item > div:nth-child(1) .title-author-content span {
        font-size: 14.4px;
        font-family: Open Sans, sans-serif; }
    .calendar-item > div:nth-child(1) .icon-wrapper {
      margin-bottom: 14px; }
  .calendar-item > div:nth-child(2) {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    font-family: pi-text, sans-serif; }
    .calendar-item > div:nth-child(2) > span:nth-child(1) {
      color: #017fbd;
      font-size: 12px;
      margin-right: 35px;
      line-height: 41px;
      text-transform: uppercase;
      position: relative;
      order: 2; }
      @media all and (max-width: 480px) {
        .calendar-item > div:nth-child(2) > span:nth-child(1) {
          margin-right: 0;
          max-width: 250px;
          line-height: 1.16667rem; } }
    .calendar-item > div:nth-child(2) > span:nth-child(2) {
      order: 1;
      align-self: flex-end; }
      .calendar-item > div:nth-child(2) > span:nth-child(2) > span:nth-child(1) {
        position: relative;
        bottom: 8px;
        left: 10px; }
  @media all and (min-width: 820px) {
    .calendar-item {
      flex-direction: row;
      justify-content: space-between;
      padding: 21px;
      margin-bottom: 7px; }
      .calendar-item > div:nth-child(1) {
        flex-direction: row; }
        .calendar-item > div:nth-child(1) .icon-wrapper {
          background-color: #f5f5fa;
          border-radius: 7px;
          width: 53px;
          height: 53px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 0; }
      .calendar-item > div:nth-child(2) {
        position: inherit;
        flex-direction: row; }
        .calendar-item > div:nth-child(2) > span:nth-child(1) {
          order: 1;
          margin-right: 35px;
          position: initial; }
        .calendar-item > div:nth-child(2) > span:nth-child(2) {
          order: 2; }
          .calendar-item > div:nth-child(2) > span:nth-child(2) > span:nth-child(1) {
            position: initial; } }
