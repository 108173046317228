#referral-button-bar {
  position: relative;
  background-color: #453b85;
  box-shadow: -12px 12px 12px 0 #ebebf6;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column; }
  #referral-button-bar .open-bar-button {
    background-color: transparent;
    color: #fff;
    font-family: pi-text, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    padding: 14px 14px 0 14px;
    text-decoration: underline;
    cursor: pointer;
    border-width: 0px; }
  #referral-button-bar .message {
    color: #fff;
    font-family: Open Sans, sans-serif;
    font-size: 14.5px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    padding: 7px 14px 14px 14px; }
  #referral-button-bar .close-button {
    width: 42px;
    position: absolute;
    padding: 0;
    right: 7px;
    top: 7px; }
  @media all and (min-width: 992px) {
    #referral-button-bar {
      height: 56px;
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      justify-content: center; }
      #referral-button-bar .open-bar-button {
        display: inline-flex;
        padding: 10px;
        cursor: pointer;
        border: none; }
      #referral-button-bar .message {
        display: inline-flex;
        padding: 10px; } }
