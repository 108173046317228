.skeleton-topbar {
  display: flex;
  align-items: center;
  width: 700px;
  padding: 7px; }
  .skeleton-topbar .divider {
    border-left: 1px solid #c8c8d3;
    display: inline-block;
    height: 42px;
    margin: 0 14px; }
  .skeleton-topbar .user-info-skeletons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 14px; }
  .skeleton-topbar .avatar-skeleton {
    border-radius: 100%; }
