.home-newsletter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #453b85;
  position: relative; }
  .home-newsletter span.message {
    font-size: 14.4px;
    font-family: Open Sans, sans-serif; }
  .home-newsletter .hide-tooltip {
    display: none; }
  .home-newsletter .newsletter-form {
    display: flex;
    margin-left: 28px;
    border-radius: 28px;
    margin-top: 14px;
    max-height: 42px; }
    .home-newsletter .newsletter-form .email-field {
      font-family: Open Sans, sans-serif;
      margin: 0;
      border: none;
      color: #fff;
      border: 1px solid;
      border-color: #b3abf5;
      font-size: 14.4px;
      border-radius: 28px 0 0 28px;
      background-color: #453b85;
      filter: brightness(0.8); }
    .home-newsletter .newsletter-form .subscribe-button {
      padding: 0 14px;
      font-family: pi-text, sans-serif;
      position: relative;
      display: inline-block;
      right: 21px;
      font-size: 14.4px;
      min-width: 120px;
      max-height: inherit;
      text-align: center;
      line-height: 0;
      color: #fff;
      box-shadow: none;
      cursor: pointer; }
      .home-newsletter .newsletter-form .subscribe-button:not(:disabled):hover {
        filter: brightness(0.8); }
      .home-newsletter .newsletter-form .subscribe-button.success {
        cursor: default;
        pointer-events: none; }
      .home-newsletter .newsletter-form .subscribe-button.loading {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center; }
      .home-newsletter .newsletter-form .subscribe-button:disabled {
        color: #453b85; }
      .home-newsletter .newsletter-form .subscribe-button .success-text {
        display: flex;
        align-items: center;
        justify-content: center; }
        .home-newsletter .newsletter-form .subscribe-button .success-text .icon {
          position: relative;
          top: 1px; }
      @media all and (max-width: 820px) {
        .home-newsletter .newsletter-form .subscribe-button {
          padding: 0 !important; } }
    .home-newsletter .newsletter-form ::placeholder {
      color: #b3abf5;
      opacity: 1; }
    .home-newsletter .newsletter-form :-ms-input-placeholder {
      color: #b3abf5; }
    .home-newsletter .newsletter-form ::-ms-input-placeholder {
      color: #b3abf5; }
  @media all and (min-width: 820px) {
    .home-newsletter {
      flex-direction: row;
      max-height: 70px; }
      .home-newsletter .newsletter-form {
        margin-top: 0; } }
  @media all and (max-width: 767px) {
    .home-newsletter .newsletter-form {
      width: 100%;
      padding: 0 7px;
      margin: 14px auto 0;
      position: relative;
      left: 7px; }
      .home-newsletter .newsletter-form button.subscribe-button {
        right: 14px;
        width: 110px; }
      .home-newsletter .newsletter-form button.success {
        margin: auto; } }
