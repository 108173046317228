.pricing {
  padding-bottom: 14px; }
  .pricing h1 {
    margin: 0;
    max-width: 100%;
    text-align: center; }
  .pricing .layout-full-width_content_container .gradient {
    position: relative; }
    .pricing .layout-full-width_content_container .gradient > * {
      position: relative;
      z-index: 2; }
    .pricing .layout-full-width_content_container .gradient::after {
      background-color: rgba(0, 0, 0, 0.2);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0; }
  .pricing .about {
    text-align: center;
    padding-top: 14px; }
    .pricing .about p,
    .pricing .about h3 {
      max-width: 100%; }

.testimonials {
  padding-bottom: 14px;
  padding-top: 56px; }
  .testimonials .first-row {
    margin-bottom: 56px;
    margin-top: -14px; }
  .testimonials .second-row {
    margin-bottom: 84px; }
    .testimonials .second-row > div {
      display: flex;
      flex-direction: column;
      align-items: center; }
    .testimonials .second-row > div:nth-child(1) {
      margin-bottom: 35px; }
    .testimonials .second-row p {
      z-index: 2;
      position: relative;
      text-align: center;
      margin-top: 3em; }
  .testimonials .balance-text {
    text-align: center;
    margin: auto; }
  .testimonials .big-quote {
    font-family: pi-display, sans-serif;
    font-size: 15em;
    display: block;
    position: relative;
    top: 60px;
    z-index: 1;
    height: 60px;
    margin-top: 50px; }

.pricing-hero .pricing-header {
  margin-top: 84px;
  margin-bottom: 14px;
  display: flex;
  justify-content: center; }
  @media all and (min-width: 1170px) {
    .pricing-hero .pricing-header {
      justify-content: flex-start; } }
  .pricing-hero .pricing-header p {
    font-size: 22px;
    line-height: 30px; }
  .pricing-hero .pricing-header #membership-switch {
    margin-left: 21px; }
    @media all and (max-width: 480px) {
      .pricing-hero .pricing-header #membership-switch {
        margin-left: 0; } }

.pricing-hero .pricing-guarantee {
  color: #4d4a4c;
  display: flex;
  justify-content: flex-start;
  margin-top: 7px; }
  @media all and (max-width: 480px) {
    .pricing-hero .pricing-guarantee {
      padding: 0 7px; } }
  .pricing-hero .pricing-guarantee p {
    font-size: 16px;
    line-height: 21px;
    margin: 0;
    max-width: 576px;
    padding: 0; }

.pricing-hero .card-wrapper {
  margin-bottom: 1.5rem; }
  @media all and (max-width: 1170px) {
    .pricing-hero .card-wrapper {
      margin-bottom: 0; } }

.pricing-hero > div {
  margin-left: -1.555556rem;
  margin-right: -1.555556rem; }

.pricing-hero h2 {
  word-break: break-all;
  text-align: center;
  margin: auto;
  margin-bottom: 7px; }

.pricing-hero .box {
  font-size: 14.4px;
  line-height: 22.5px;
  padding: 0 56px !important;
  text-align: center; }
  @media all and (max-width: 1170px) {
    .pricing-hero .box {
      margin-bottom: 35px;
      padding: 0 21px !important; } }
  .pricing-hero .box .icon {
    color: #fff; }

.pricing-hero .memberships .membership-card {
  display: flex;
  flex-direction: column;
  height: 100%; }
  @media all and (max-width: 1170px) {
    .pricing-hero .memberships .membership-card {
      height: auto;
      margin-bottom: 35px !important;
      padding: 14px;
      padding-bottom: 21px; }
      .pricing-hero .memberships .membership-card .description {
        margin-bottom: 35px; } }

#membership-switch {
  margin-bottom: 35px;
  background-color: #fff;
  padding: 6px;
  border-radius: 50px; }
  @media all and (max-width: 480px) {
    #membership-switch {
      border-radius: 32px; } }
  #membership-switch button {
    box-shadow: none; }
    @media all and (max-width: 480px) {
      #membership-switch button {
        margin: 0; } }
    #membership-switch button.secondary {
      background-color: #fff;
      border: none; }

@media all and (min-width: 992px) {
  .testimonials .first-row {
    margin-bottom: 84px;
    margin-top: 35px; }
  .testimonials .second-row > div {
    align-items: flex-start; }
  .testimonials .second-row > div:nth-child(1) {
    margin-right: 35px;
    border-right: 1px solid #ebebf6; }
  .testimonials .second-row > div:nth-child(2) {
    position: relative;
    left: 84px; }
  .testimonials .second-row p {
    text-align: left;
    margin-top: 0;
    max-width: 353px; }
  .testimonials .big-quote {
    display: block;
    position: relative;
    top: 113px;
    right: 61px;
    margin-top: 0;
    opacity: 0.7; } }
