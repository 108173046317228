#author #header {
  text-align: -webkit-center !important; }
  #author #header .complete-affiliation > * {
    font-size: 22.5px;
    line-height: 1.94444rem; }
  #author #header .title {
    color: #4d4a4c;
    font-family: pi-text, sans-serif;
    font-size: 22.5px;
    margin: 28px; }
  #author #header .avatar {
    text-align: unset; }
  #author #header .description {
    font-family: Open Sans, sans-serif;
    font-size: 22.5px; }

#author .footer {
  margin-top: 5rem; }
