.dock-modal {
  background: linear-gradient(360deg, #000000e0 25%, #f5f5fa00 100%);
  bottom: 0px;
  cursor: not-allowed;
  height: calc(100% - 84px);
  left: 0px;
  opacity: 0;
  position: fixed;
  right: 0px;
  z-index: 99;
  display: flex;
  align-items: flex-end;
  justify-content: center; }
  .dock-modal.show {
    opacity: 1; }
    .dock-modal.show .dock-modal-content {
      transform: translateY(0); }
  .dock-modal .dock-modal-content {
    background-color: #fff;
    border-top: 1px solid #4d4a4c;
    box-shadow: #231f20 0px 0px 3px;
    cursor: auto;
    padding: 66px 0;
    transform: translateY(40vh);
    transition: all 750ms ease-in-out;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center; }
