.simple_page {
  flex-direction: columns; }
  .simple_page #logo {
    padding: 0px;
    margin-left: -7px; }
  .simple_page h1 {
    margin: 0px; }
  @media only screen and (max-width: 480px) {
    .simple_page .group + .group {
      padding-bottom: 21px; } }
  .simple_page .segment:last-child {
    margin: 56px 0; }
    .simple_page .segment:last-child h6 {
      margin-bottom: 28px; }
    @media only screen and (max-width: 480px) {
      .simple_page .segment:last-child {
        margin: 28px 0; } }
  @media only screen and (max-width: 480px) {
    .simple_page p {
      font-size: 14.4px;
      padding-bottom: 14px; } }
